import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme, Theme, createTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import HomeIcon from '@material-ui/icons/Home';
import MailIcon from '@material-ui/icons/Mail';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';

import { useAuth0 } from "@auth0/auth0-react";
import AuthNav from './auth-nav';
import { User, MENU_ANGEBOTE, MENU_VERTRAGSUNTERLAGEN, MENU_KUNDEN, COC_FETCHDATA, MENU_ANGEBOTE_MITARBEITER, LG_KONF_VL } from '../auth/User';

import toast from 'react-hot-toast';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';


const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
({
    root: {
        display: 'flex',
        height: '70px'
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        backgroundColor: '#ee741d',
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
    title: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },

}),
);

export default function PersistantDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [menueTitle, setTitle] = React.useState('Haas Kalkulationstool');
    const { isAuthenticated } = useAuth0();
    const versionData = getVersionData();

    const [VersionDialog, setVersionDialog] = React.useState(false);


    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleMenuTitle = (title) => {
        setTitle(title);
    };

    const handleShowSite = (site) => {
        console.log("SHowSite: " + `./${site}`)
    };

    const handleShowVersion = async () => {
        setVersionDialog(true);
        var versionDescription = getVersionData().Tasks;
        console.log(versionDescription);
        //toast.success(versionDescription);
    };

    const handleCloseVersion = async () => {
        setVersionDialog(false);
        //toast.success(versionDescription);
    };

    function getVersionData() {
        let file = null;
        switch (window.location.hostname) {
            case process.env["REACT_APP_Hostname_localhost"]:
                file = require('./data/version_local.json');
                break;
            case process.env["REACT_APP_Hostname_Haas_Prod"]:
                file = require('./data/version_Prod.json');
                break;
            case process.env["REACT_APP_Hostname_Haas_Prod_Custom_Domain"]:
                file = require('./data/version_Prod.json');
                break;
            case process.env["REACT_APP_Hostname_Dev"]:
                file = require('./data/version_Dev.json');
                break;
            case process.env["REACT_APP_Hostname_Haas_Test"]:
                file = require('./data/version_Test.json');
                break;
            case process.env["REACT_APP_Hostname_Haas_Dev"]:
                file = require('./data/version_Dev.json');
                break;
            case process.env["REACT_APP_Hostname_Testing"]:
                file = require('./data/version_Test.json');
                break;
            case process.env["REACT_APP_Hostname"]:
                file = require('./data/version_Prod.json');
                break;
            default:
                file = require('./data/version_empty.json');
        }
        return file;
    }




    return (
        <div className={classes.root}>
            <Dialog
                open={VersionDialog}
                onClose={handleCloseVersion}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Version Info: "} {versionData.Version} - {versionData.host}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {versionData.Tasks.map((x) => (
                            <div id={x.number}>
                                <Typography gutterBottom>
                                    <b style={{ color: "black" }}>{x.number}</b>
                                </Typography>
                               
                                <Typography gutterBottom>
                                    <b style={{ fontSize:"small" }}>{x.description}</b>
                                </Typography>
                                <hr />
                            </div>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseVersion} color="primary" autoFocus>
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>


            <CssBaseline />
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    {!isAuthenticated ? '' : <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, {
                            [classes.hide]: open,
                        })}
                    >

                        <MenuIcon />
                    </IconButton>}

                    <Typography variant="h6" noWrap className={classes.title}>
                        {menueTitle}
                    </Typography>
                    {!isAuthenticated ? '' : <button style={{ marginRight: "10px" }} onClick={() => handleShowVersion()}> Version {versionData.Version}</button>}
                    
                    <AuthNav />
                </Toolbar>
            </AppBar>
            <div>
                <main className={clsx(classes.content, { [classes.contentShift]: open, })}>
                    <div className={classes.drawerHeader} />
                    {handleShowSite}
                </main>
            </div>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{ paper: classes.drawerPaper, }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>

                <List>
                    <ListItem button key='Angebote' to='/angebote' component={Link} onClick={handleDrawerClose} className={User().can(MENU_ANGEBOTE) ? '' : classes.hide}>
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary='Meine Angebote' />
                    </ListItem>
                    <ListItem button key='MitArbeiter' to='/mitarbeiter' component={Link} onClick={handleDrawerClose} className={User().can(MENU_ANGEBOTE_MITARBEITER) ? '' : classes.hide}>
                        <ListItemIcon>
                            <FormatListBulletedIcon />
                        </ListItemIcon>
                        <ListItemText primary={User().can(LG_KONF_VL) ? 'Angebote meiner HV' : 'Alle Angebote'} />
                    </ListItem>
                    {/*<ListItem button key='Kunden' to='/kunden' component={Link} onClick={handleDrawerClose} disabled={!User().can(MENU_KUNDEN)}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <PeopleIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary='Kunden' />*/}
                    {/*</ListItem>*/}
                    <ListItem button key='Vertragsunterlagen' to='/vertragsunterlagen' component={Link} onClick={handleDrawerClose} disabled={!User().can(MENU_VERTRAGSUNTERLAGEN)}>
                        <ListItemIcon>
                            <DescriptionIcon />
                        </ListItemIcon>
                        <ListItemText primary='Vertragsunterlagen' />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button key='Hip' href='https://haasfertigbau.sharepoint.com/sites/HIP_HAB' target='_blank' component={'a'} onClick={handleDrawerClose}>
                        <ListItemIcon>
                            <MailIcon />
                        </ListItemIcon>
                        <ListItemText primary='HIP' />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    {/*<ListItem button key='Home' to='/' component={Link} onClick={handleDrawerClose}>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <HomeIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary='Home' />*/}
                    {/*</ListItem>*/}
                    {/*<Divider />*/}
                    <ListItem button key='FetchData' to='/fetch-data' component={Link} onClick={handleDrawerClose} className={User().can(COC_FETCHDATA) ? '' : classes.hide}>
                        <ListItemIcon>
                            <AttachMoneyIcon />
                        </ListItemIcon>
                        <ListItemText primary='Fetch Data' />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}