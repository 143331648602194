import { useAuth0 } from "@auth0/auth0-react";

// Permissions
export const ALL = 'all'; // It gives FULL access to all actions (exclude COC Internal Actions), no need to put another permissions (only use for admins!!!)

// INTERNAL permissions ONLY for COC (ALL has not include this permissions)
export const COC_FETCHDATA = 'coc:fetchdata';

// uses for access only to menu buttons
export const MENU_ANGEBOTE = 'menu:angebote'; // Meine Angebote // IMPORTANT: it is excluded from ALL permission and must be added extra if necessary
export const MENU_ANGEBOTE_MITARBEITER = 'menu:angebote:mitarbeiter'; // Alle Angebote
export const MENU_VERTRAGSUNTERLAGEN = 'menu:vertragsunterlagen';
export const MENU_KUNDEN = 'menu:kunden';

// uses for access to components and pages
export const ANGEBOTE = 'angebot:list'; // IMPORTANT: it is excluded from ALL permission and must be added extra if necessary
export const ANGEBOTE_MITARBEITER = 'angebot:mitarbeiter';
export const ANGEBOT_FORM_OPEN = 'angebot:form:open';
export const ANGEBOT_FORM_SAVE = 'angebot:form::save';
export const ANGEBOT_FORM_BASISPREISE = 'angebot:form::basispreise';
export const ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE = 'angebot:form::sonderkalkulationsbausteine';
export const ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN = 'angebot:form::einzelpreise:andrucken';
export const ANGEBOT_FORM_DOWNLOAD_CSV = 'angebot:form::download:csv';
export const ANGEBOT_EXPORT_TO_INCREASE_BUTTON = 'angebot:export:to:increase:button';
export const ANGEBOT_FORM_EMAIL = 'angebot:email:button';
export const ANGEBOT_FORM_DOWNLOAD = 'angebot:email:button';
export const ANGEBOT_ATTACHMENTS_PANEL = 'angebot:attachments:panel';

export const VERTRAGSUNTERLAGEN = 'vertragsunterlagen:list';
export const KUNDEN = 'kunden:list';

// special permission which can be used to determine the role
export const LG_KONF_VL = 'role:lg:konf:vl';


// Mapping list (keys must be the same with roles in Auth0)
const mappingMatrix = {
    'Calctool Admins': [ // role from COC Azure
        ALL,
        COC_FETCHDATA, // must stay here because it was excluded from ALL permission
    ],
    'LG-KONF-AUDMA': [
        MENU_ANGEBOTE, 
        ANGEBOTE,
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD
    ],
    //'LG-KONF-AUDMA-OP': [
    //    MENU_ANGEBOTE,
    //    ANGEBOTE,
    //    MENU_VERTRAGSUNTERLAGEN,
    //    VERTRAGSUNTERLAGEN,
    //    ANGEBOT_FORM_OPEN,
    //    ANGEBOT_FORM_EMAIL,
    //    ANGEBOT_FORM_DOWNLOAD
    //],
    'LG-KONF-IDMA': [
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        MENU_ANGEBOTE_MITARBEITER,
        ANGEBOTE_MITARBEITER,
        ANGEBOT_FORM_BASISPREISE,
        ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN,
        ANGEBOT_FORM_DOWNLOAD_CSV,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD,
        ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE,
        ANGEBOT_ATTACHMENTS_PANEL
    ],
    'LG-KONF-VL': [
        LG_KONF_VL,
        MENU_ANGEBOTE,
        ANGEBOTE,
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        MENU_ANGEBOTE_MITARBEITER,
        ANGEBOTE_MITARBEITER,
        ANGEBOT_FORM_BASISPREISE,
        ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN,
        //ANGEBOT_FORM_DOWNLOAD_CSV,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD
    ],
    'LG-GVL': [
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        MENU_ANGEBOTE_MITARBEITER,
        ANGEBOTE_MITARBEITER,
        ANGEBOT_FORM_BASISPREISE,
        ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN,
        //ANGEBOT_FORM_DOWNLOAD_CSV,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD,
        ANGEBOT_ATTACHMENTS_PANEL
    ],
    'LG-KONF-ADMIN': [
        ALL,
    ],
    'LG-KONF-ARCHITEKT': [
        MENU_ANGEBOTE_MITARBEITER,
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        ANGEBOT_FORM_BASISPREISE,
        ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN,
        //ANGEBOT_FORM_DOWNLOAD_CSV,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD
    ],
    'LG-KONF-BEMUSTERER': [
        MENU_ANGEBOTE_MITARBEITER,
        MENU_VERTRAGSUNTERLAGEN,
        VERTRAGSUNTERLAGEN,
        ANGEBOT_FORM_BASISPREISE,
        ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN,
        //ANGEBOT_FORM_DOWNLOAD_CSV,
        ANGEBOT_FORM_OPEN,
        ANGEBOT_FORM_EMAIL,
        ANGEBOT_FORM_DOWNLOAD
    ],
    'LG-KONF-AV': [
        ANGEBOT_EXPORT_TO_INCREASE_BUTTON,
        MENU_ANGEBOTE_MITARBEITER,
        ANGEBOTE_MITARBEITER, 
    ],
};

export function User() {
    const { isLoading, isAuthenticated, user } = useAuth0();
    const getUserRolesKey = () => {
        let key = '';

        switch (window.location.hostname) {
            case process.env["REACT_APP_Hostname"]:
                key = process.env["REACT_APP_Api_Path_Gen"];
                break;
            /*case process.env["REACT_APP_Hostname_Haas_Test"]: // TODO: uncomment and put here just another variables from .env if Haas User from Auth0 has other key for roles list
                key = process.env["REACT_APP_Api_Path_Gen_Haas_Prod"];
                break;*/
            default:
                key = process.env["REACT_APP_Api_Path_Gen"];
        }

        // check and remove trailing slash from the end if it exists
        key = key.endsWith('/') ? key.slice(0, -1) : key;
        return key + '/groups';
    };

    const getUserRolesName = () => {
        if (!isAuthenticated) {
            return '';
        }

        let rolesName = [];
        let userRoles = user[getUserRolesKey()];

        if (userRoles) {
            userRoles.forEach(role => {
                if (mappingMatrix[role] ?? false) {
                    rolesName.push(role);
                }
            });
        }

        return rolesName.join(', ');
    };

    //console.log('Current User', user);
    //console.log('userRolesKey', getUserRolesKey());

    // Checks if current user can some action
    // returns boolean
    const can = (permission) => {
        if (!isAuthenticated) { // means Anonym user
            return false;
        }

        let userRoles = user[getUserRolesKey()];
        let isUserCan = false;
        //console.log('Current User Roles', userRoles);

        // if the perminssion was found, it sets on true
        if (userRoles) {
            userRoles.forEach(role => {
                let currentPermissions = mappingMatrix[role] ?? [];

                // TODO: it is good to make some allow and disallow sub arrays for mappingMatrix and re-write this part to get more flexible
                if ((![LG_KONF_VL, MENU_ANGEBOTE, ANGEBOTE, COC_FETCHDATA].includes(permission) && currentPermissions.includes(ALL)) || currentPermissions.includes(permission)) {
                    isUserCan = true;
                    return;
                }
            });
        }

        // by default returns false,
        // means user must have one of defined roles
        return isUserCan;
    };

    return { can, isLoading, getUserRolesName };
};