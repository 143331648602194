import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import Angebote from './Angebote';


import { User, ANGEBOTE_MITARBEITER } from '../../auth/User';

export function MitArbeiter() {
    const currentUserState = User();

    useEffect(() => {
        // check user permissions to this page
        if (!currentUserState.isLoading && !currentUserState.can(ANGEBOTE_MITARBEITER)) {
            toast.error('403 Zugriff verweigert');
        } 
    }, [currentUserState.isLoading]);

    return (
        <>
            {!currentUserState.can(ANGEBOTE_MITARBEITER) ? '' : <Angebote includeReportsMe='true' />}
        </>
    );
}