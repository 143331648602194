export const formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const formatterDecimal = new Intl.NumberFormat('de-DE', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});

export const formatDate = (date) =>{
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [day, month, year].join('.');
}



// data and columns are params from material-table exportCsv
export const handleExportCSV = (fileName, data, columns) => {
    const delimiter = ";";
    const BOM = "\uFEFF";

    columns = columns.filter(columnDef => {
        return columnDef.byCsvExportForceInclude === true || !columnDef.hidden && columnDef.field && columnDef.export !== false;
    });
    let dataRows = data.map(rowData => // if type of field is currency, then format value
        columns.map(columnDef => columnDef.byCsvExportFormattedAs == 'number' ? formatterDecimal.format(rowData[columnDef.field]) : rowData[columnDef.field])
    );
    let headerRows = columns.map(columnDef => columnDef.title);
    let csvContent = BOM + [headerRows, ...dataRows].map(e => e.join(delimiter)).join("\n");
    let csvFileName = fileName;

    downloadCsv(csvContent, csvFileName);
}

export const downloadCsv = (data, fileName) => {
    const finalFileName = fileName.endsWith(".csv") ? fileName : `${fileName}.csv`;
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([data], { type: "text/csv;charset=utf-8" }));
    a.setAttribute("download", finalFileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}


// by default that will get to download PDF file,
// but if fileNameWithExtension has "doc" or "docx" - then it will return ms word file
export const downloadFile = (data, fileNameWithExtension, mimeType = '', isBase64 = false) => {
    let contentType = '"application/pdf;charset=utf-8"';

    if (fileNameWithExtension.endsWith(".docx") || fileNameWithExtension.endsWith(".doc")) {
        contentType = '"application/msword;charset=utf-8"';
    } else if (fileNameWithExtension.endsWith(".jpg") || fileNameWithExtension.endsWith(".jpeg")) {
        contentType = '"image/jpeg"';
    }

    //if mimeType manually setted, then it has higher priority
    if (mimeType && mimeType.length > 0) {
        contentType = '"' + mimeType + '"';
        //contentType = mimeType;
    }

    let href = '';
    if (isBase64 === true) {
        href = `data:${contentType};base64,${data}`;
    } else {
        href = window.URL.createObjectURL(new Blob([data], { type: contentType }));
    }
    
    if (href) {
        let link = document.createElement('a');
        link.href = href;
        link.setAttribute('target', '_blank');
        link.setAttribute('download', fileNameWithExtension);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        alert('Datei ist nicht vorhanden');
    }
}