import React, { useEffect } from 'react';
import toast from 'react-hot-toast';

import { User, KUNDEN } from '../../auth/User';

export function Kunden() {
    const currentUserState = User();

    useEffect(() => {
        // check user permissions to this page
        if (!currentUserState.isLoading && !currentUserState.can(KUNDEN)) {
            toast.error('403 Zugriff verweigert');
        }
    }, [currentUserState.isLoading]);

    return (
        <>
            {!currentUserState.can(KUNDEN) ? '' :
                <>
                    <h2>Kunden</h2>
                    <p>Die Seite befindet sich im Aufbau</p>
                </>
            }
        </>
    );
}
