import React, { useState, useEffect, useRef } from 'react';

import {
    makeStyles, createStyles,
    Grid, TextField, FormControl, Select, Button, Checkbox,
    Input, InputLabel, MenuItem, ListItemText, Chip,
    Typography, Tooltip,
    Accordion, AccordionSummary, AccordionDetails,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControlLabel, Box,
    TableHead, TableRow, TableCell
} from "@material-ui/core";

import MaterialTable from 'material-table';
import MaterialTableIcons from '../../components/MaterialTableIcons';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MapIcon from '@material-ui/icons/Map';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import QueueIcon from '@material-ui/icons/Queue';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { formatter, formatterDecimal, handleExportCSV, downloadFile, formatDate } from '../../helper';

import toast from 'react-hot-toast';

import SpinnerPopover from '../../components/SpinnerPopover';
import { useMakeRequest } from '../../api/Requests';

import { User, ANGEBOT_FORM_BASISPREISE, ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE, ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN, ANGEBOT_FORM_DOWNLOAD_CSV } from '../../auth/User';


const listofChange = [
    { name: "AG", value: "100" },
    { name: "FG", value: "200" },
    { name: "DG", value: "300" }
]
const useStyles = makeStyles((theme) =>
    createStyles({
        expandGrid: {
            width: '100%',
            paddingTop: '2px',
            paddingBottom: '2px',
            paddingLeft: '5px',
            paddingRight: '5px',
            marginLeft: '0px',
            marginRight: '8px',
            marginTop: '20px',
            marginBottom: '5px'
        },
        gridContainerRow: {
            marginBottom: '10px'
        },
        textField: {
            width: '100%',
        },
        discountTextField: {
            width: '60px',
            "& .Mui-error": {
                width: '400px',
            },
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        formControlFullWidth: {
            margin: theme.spacing(1),
            minWidth: 120,
            //maxWidth: 300,
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function AngebotForm({ ...props }) {
    const OFFER_STATE_FINAL = 4; // if ID will be changed on Backend, it must be changed here too
    const OFFER_STATE_ABGELEHNT = 5; // if ID will be changed on Backend, it must be changed here too
    const OFFER_STATE_BEARBEITUNG = 2; // if ID will be changed on Backend, it must be changed here too
    const OFFER_STATE_STORNO = 8; // if ID will be changed on Backend, it must be changed here too
    //const OFFER_STATE_UNTERSCHRIEBEN = 7; // if ID will be changed on Backend, it must be changed here too

    const styles = useStyles();
    const [myValues, setMyValues] = useState({});
    const [myErrors, setMyErrors] = useState({});
    const [tableTemplatesData, setTableTemplatesData] = useState([]);
    const [detailsTableColumns, setDetailsTableColumns] = useState([]);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isTemplatesLoaded, setIsTemplatesLoaded] = useState(false);
    const [myMandantId, setMyMandantId] = useState(0);
    const [myMandant, setMyMandant] = useState(null);
    const myTableRef = useRef(null);
    // initialize custom API useEffect to make requests
    const { request, error } = useMakeRequest();

    // initialize custom User Component to check permisions
    const currentUserState = User();

    const offerItemsRef = useRef([]);

    const dateTimeFullFormt = (givenDate) => {

        var year = new Date(givenDate).getFullYear();
        var month = new Date(givenDate).getMonth();
        var day = new Date(givenDate).getDate();

        let date = new Date(year, month, day).toLocaleDateString("de-DE", "yyyy.MM.dd hh:mm")
        let time = new Date(givenDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
        return `${date} ${time}`

    }

    // is a reference which contains all sonder products
    const [mySonderProducts, setMySonderProducts] = useState([]);




    useEffect(() => {
        if (props.isOpen) {
            let mandantId = 0;
            let mandant = null;
            request('getOfferByOfferId', props.currentId).then(response => {
                if (error.length === 0 && typeof response !== 'undefined') {
                    console.log('response for request(\'getOfferByOfferId\')', response);
                    console.log('allSonderProducts', props.allSonderProducts);
                    mandantId = response.dataAreaId;
                    setMyMandantId(mandantId);
                    mandant = response.dataArea;
                    setMyMandant(mandant);
                    let costingVersion = response.costingVersion;
                    let costingVersions = props.allSonderProducts.filter(sonder => sonder.costingVersion === costingVersion && 
                                                                            sonder.dataAreaId === mandantId);
                    setMySonderProducts(costingVersions);
                    console.log('mySonderProducts', mySonderProducts);
                    // save all offer items to reference
                    offerItemsRef.current = response.offerItem;


                    let getClientName = (clientModel) => {
                        return (clientModel.namePrefix && clientModel.namePrefix.length > 0 ? clientModel.namePrefix + ' ' : '') + (clientModel.firstName && clientModel.firstName.length > 0 ? clientModel.firstName + ' ' : '') + (clientModel.lastName && clientModel.lastName.length > 0 ? clientModel.lastName : '');
                    };
                    let currClients = props.customerClients.filter(customer => customer.customerId === response.customerId)[0]?.clients.sort((a, b) => a.primary < b.primary && 1 || -1).map(c => {
                        return {
                            name: getClientName(c),
                            email: c.email,
                            telephone: c.mobile ? c.mobile + (c.phone ? ', ' + c.phone : '') : (c.phone ? c.phone : ''),
                            address: c.street + ', ' + c.zipCode + ' ' + c.city + ', ' + c.country,
                            primary: c.primary
                        }
                    });

                    let currWorkingCosts = response.offerWorkingCostDetails.map(item => {
                        return {
                            no: item.itemNumber,
                            name: item.description,
                            sumPrice: item.price,
                            productConfigurationId: item.productConfigurationId
                        }
                    });

                    let currDetails = response.offerItem.map(item => {
                        return {
                            id: item.id,
                            optional: item.optional,
                            no: item.itemNumber,
                            kurztext: item.shortText,
                            montage: item.itemInklMontage,
                            langtext: item.longText,
                            pictureId: item.pictureId,
                            pdfId: item.pdfId,
                            menge: Math.round((item.quantity + Number.EPSILON) * 100) / 100,
                            einheit: item.unitSymbol,
                            einzelpreis: item.unitPrice,
                            sumPrice: countArtikelSumme(item, item.itemInklMontage, true),
                            productConfigurationId: item.productConfigurationId
                        }
                    });

                    let currSonderbausteine = response.offerItemSonder.map(item => {
                        return {
                            id: item.id,
                            no: item.itemNumber,
                            kurztext: item.shortText,
                            langtext: item.longText,
                            einkaufspreis: item.costPrice.toString().replaceAll('.', ','),
                            verkaufspreis: item.unitPrice.toString().replaceAll('.', ',')
                        }
                    });

                    let currBauort = response.csStreet ? response.csStreet : '';
                    currBauort = response.csZipCode ? (currBauort.length !== 0 ? currBauort + ', ' + response.csZipCode : response.csZipCode) : currBauort;
                    currBauort = response.csCity ? (currBauort.length !== 0 ? currBauort + ' ' + response.csCity : response.csCity) : currBauort;
                    currBauort = response.csState ? (currBauort.length !== 0 ? currBauort + ', ' + response.csState : response.csState) : currBauort;
                    currBauort = response.csCountryKey ? (currBauort.length !== 0 ? currBauort + ', ' + response.csCountryKey : response.csCountryKey) : currBauort;

                    let preparedData = {
                        id: response.id, // offer id in database
                        customerId: response.customerId, // means as parentId in table view mode
                        angebotsnummer: response.offerNumber,
                        name1: currClients[0] ? currClients[0].name : '',
                        name2: currClients[1] ? currClients[1].name : '',
                        costingVersion: response.costingVersion ? response.costingVersion : 0,
                        handelsvertreter: response.assignedTo,
                        erstelldatum: response.offerDate,
                        titelHausvorschlag: response.houseTitle,
                        ersteller: response.createdBy,
                        status: response.offerStageId,
                        bemerkungsfeld: response.description,
                        clients: currClients,
                        haustype: response.buildingStage,
                        ausfuehrung: response.floorVariation,
                        bauort: currBauort,
                        bebauteFlaeche: response.builtArea,
                        wohnflaecheEg: response.livingSpaceGroundFloor,
                        wohnflaecheDg: response.livingSpaceTopFloor,
                        dachneigung: response.roofPitch,
                        kniestock: response.jambWall,
                        maklerprovision: response.brokerProvision,
                        kalkulationsreserve: response.calculationReserve,
                        bemusterungsgutschein: response.supplyVoucherId,
                        basispreise: currWorkingCosts,
                        workingCost: formatter.format(response.workingCost),
                        details: currDetails,
                        angebotsSumme: response.price,
                        offerRevision: response.offerRevision,
                        offerRevisionsdatum: dateTimeFullFormt(response.createdAt),
                        templates: response.templates,
                        anzeigeEinzelpreisenCheckbox: false, // by default is unchecked
                        druckenEinzelpreisenCheckbox: response.printUnitPrice,
                        druckenZwieschensummenCheckbox: response.printSubTotalPrice,
                        druckenBemusterungsgutscheinCheckbox: response.printSupplyVoucher,
                        finalisierenCheckbox: response.offerStateId == OFFER_STATE_FINAL ? true : false, // this value can be changed with checkbox
                        unterschriebenCheckbox: response.signed,
                        offerStateId: response.offerStateId,
                        offerLivingSpacePerStoreys: response.offerLivingSpacePerStoreys,
                        isFinal: response.offerStateId == OFFER_STATE_FINAL ? true : false, // if true then disable all actions
                        isAbgelehnt: response.offerStateId == OFFER_STATE_ABGELEHNT ? true : false, // if true then disable all actions
                        isStorno: response.offerStateId == OFFER_STATE_STORNO ? true : false, // if true then disable all actions
                        isUntergeschrieben: response.signed, // if true then disable all actions
                        discountsAll: response.discountType,
                        discounts: response.offerDiscount && Object.keys(response.offerDiscount).length !== 0 ? response.offerDiscount.map(item => { return { tblDcTypeId: item.tblDcTypeId, amount: item.amount, disabled: item.disabled } }) : [],
                        sonderkalkulationsbausteine: currSonderbausteine,
                        iw: formatterDecimal.format(response.iw) + 'm²',
                        aw: formatterDecimal.format(response.aw) + 'm²',
                        de: formatterDecimal.format(response.de) + 'm²',
                        da: formatterDecimal.format(response.da) + 'm²',
                    };

                    //console.log('preparedData', preparedData);
                    setMyValues(preparedData);

                    console.log("preparedData", preparedData.offerLivingSpacePerStoreys)
                    //// get all templates
                    //console.log('loadTemplatesData(mandant) ...');
                    //loadTemplatesData(mandant);
                }
            }).finally(() => {
                setIsDataLoaded(true);
            });

        } else {
            console.log('Form is closed, skipping init...');
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen && (isDataLoaded===true)) {
            //jetzt können die Templates geladen werden
            // get all templates
            console.log('loadTemplatesData(myMandant)...');
            loadTemplatesData(myMandant);
        }
    }, [props.isOpen, isDataLoaded]);



    const loadTemplatesData = (mandantName) => {
        request('getAllTemplates', 0, mandantName).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                if (response.length === 0) {
                    response = [];
                } else {
                    response = JSON.parse(response);
                }
                //console.log('response for request(\'getAllTemplates\')', response);

                let templatesData = [];

                if (response.length > 0) {
                    response.forEach(res => {
                        // use only PDF files
                        if (res.templateFormat.toLowerCase() == '.pdf') {
                            if (res.templateName.includes('Bemusterung') == false) {
                                templatesData.push({
                                    templateName: res.templateName,
                                    templateFormat: res.templateFormat,
                                    templateOrder: res.templateOrder

                                });
                            }
                        }
                    });
                }

                console.log('templatesData are loaded', templatesData);
                setTableTemplatesData(templatesData);
            }
        }).finally(() => {
            setIsTemplatesLoaded(true);
        });
    }

    useEffect(() => {
        let isHideEinzelpreis = !myValues.anzeigeEinzelpreisenCheckbox;
        setDetailsTableColumns([
            { title: 'id', field: 'id', hidden: true },
            { title: 'optional', field: 'optional', hidden: true },
            { title: 'ArtikelNo.', field: 'no', width: '8%' },
            { title: 'Kurztext', field: 'kurztext', width: '13%' },
            {
                title: 'Montage', field: 'montage', width: '12%',
                render: rowData => {
                    return isMontageable(rowData) ? <Button onClick={(e) => {
                        changeMontage(rowData);
                    }}>{rowData.montage ? 'inkl. Montage' : 'ohne Montage'}</Button> : '';
                }
            },
            {
                title: 'Langtext',
                field: 'langtext',
                width: isHideEinzelpreis ? '26%' : '20%',
                export: false,
                render: rowData => {
                    return (
                        <span title={rowData.langtext && rowData.langtext.length > 100 ? rowData.langtext : ''} onClick={(e) => {
                            if (rowData.langtext && e.target.innerHTML.length < 104) {
                                e.target.innerHTML = rowData.langtext;
                                e.target.title = '';
                            } else {
                                e.target.innerHTML = rowData.langtext.substring(0, 100) + '...';
                                e.target.title = rowData.langtext;
                            }
                        }}>
                            {rowData.langtext ? rowData.langtext.substring(0, 100) + (rowData.langtext.length > 100 ? '...' : '') : ''}
                        </span>
                    )
                }
            },
            { title: 'Produktkonfigurationsid', field: 'productConfigurationId', hidden: true, byCsvExportForceInclude: true }, // IMPORTANT: byCsvExportForceInclude is not official parameter but it uses to include this field to csv export even if the field is hidden
            {
                title: 'Bild', field: 'pictureId', width: '6%',
                render: rowData => {
                    return rowData.pictureId ? <Button onClick={(e) => {
                        getFileByName(rowData, 'pictureId');
                    }}><VerticalAlignBottomIcon color="primary" /></Button> : '';
                },
                filter: false,
            },
            {
                title: 'PDF', field: 'pdfId', width: '6%',
                render: rowData => {
                    return rowData.pdfId ? <Button onClick={(e) => {
                        getFileByName(rowData, 'pdfId');
                    }}><VerticalAlignBottomIcon color="primary" /></Button> : '';
                }
            },
            { title: 'Menge', field: 'menge', width: '7%', byCsvExportFormattedAs: 'number', render: rowData => { return formatterDecimal.format(rowData.menge) } }, // IMPORTANT: param "byCsvExportFormattedAs" is not official, but it uses by handleExportCSV function to correct formating the values
            { title: 'Einheit', field: 'einheit', width: '7%' },
            { title: 'Einzelpreis', field: 'einzelpreis', hidden: isHideEinzelpreis, width: '8%', byCsvExportFormattedAs: 'number', type: 'currency', currencySetting: { locale: 'de-DE', currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
            { title: 'Summe, ohne MwSt.', field: 'sumPrice', width: '10%', byCsvExportFormattedAs: 'number', type: 'currency', currencySetting: { locale: 'de-DE', currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
        ]);
    }, [myValues]);

    const getFileByName = (rowData, keyName) => {
        //console.log('getFileByName(rowData)', rowData);

        if (rowData[keyName].length < 1) {
            console.log('Something wrong...');
            return;
        }

        setIsSpinnerVisible(true);

        let fileNameWithExtension = rowData[keyName];
        let extension = fileNameWithExtension.split('.').pop().toLowerCase(); // pop extension from the array

        let params = {
            tempName: fileNameWithExtension,
            extension: extension
        };

        request('getFileByName', '', params).then(response => {
            //console.log('response for request(\'getFileByName\')', response);

            if (error.length === 0 && typeof response !== 'undefined') {
                downloadFile(response, fileNameWithExtension);
            }
        })
            .finally(() => {
                setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
            });
    }

    // offerItem is item Object, not array of items
    // inklMontage is Boolean
    // byInitialize is Boolean - we must set it on true only when setting data first time after request to backend, by default use always "simulation of price counting"
    const countArtikelSumme = (offerItem, inklMontage, byInitialize = false) => {
        let sum = offerItem.itemPriceSum;

        if (Object.keys(offerItem.offerItemComplementary).length !== 0) {
            offerItem.offerItemComplementary.forEach(item => {
                if (byInitialize) { // use it only once by initialize
                    if (inklMontage === true && item.include === true) {
                        sum = sum + item.itemPriceSum;
                    } else if (inklMontage === false && item.include === true && item.selectable === false) { // we can't exclude montage costs for not selectable items
                        sum = sum + item.itemPriceSum;
                    }
                } else {
                    if (inklMontage === true) {
                        sum = sum + item.itemPriceSum;
                    } else if (inklMontage === false && item.include === true && item.selectable === false) { // we can't exclude montage costs for not selectable items
                        sum = sum + item.itemPriceSum;
                    }
                }
            });
        }

        return sum;
    }
    // rowData from table Details
    const changeMontage = (rowData) => {
        let offerItem = offerItemsRef.current.find(item => item.id == rowData.id);
        let inklMontage = !rowData.montage;
        //console.log('changeMontage:', offerItemsRef.current, offerItem, inklMontage);

        let sum = countArtikelSumme(offerItem, inklMontage);
        //console.log('sum', sum);

        if (myValues.details && Object.keys(myValues.details).length !== 0) {
            let newDetails = myValues.details.map(item => {
                let newItem = item;
                if (item.id == offerItem.id) {
                    newItem.montage = inklMontage;
                    newItem.sumPrice = sum;
                }
                return newItem;
            });

            setMyValues({ ...myValues, ...{ details: newDetails } });
        }
    }

    // rowData from table Details
    const changeOptional = (rows) => {
        if (myValues.details && Object.keys(myValues.details).length !== 0) {
            let newDetails = myValues.details.map(item => {
                let newItem = item;
                newItem.optional = false;
                return newItem;
            });

            rows.forEach(rowData => {
                newDetails = newDetails.map(item => {
                    let newItem = item;
                    if (item.id == rowData.id) {
                        newItem.optional = true;
                    }
                    return newItem;
                });
            });

            setMyValues({ ...myValues, ...{ details: newDetails } });
        }
    }

    // if need to show button for montage changing or not
    const isMontageable = (rowData) => {
        let offerItem = offerItemsRef.current.find(item => item.id == rowData.id);
        let isPossible = false;

        if (Object.keys(offerItem.offerItemComplementary).length !== 0) {
            offerItem.offerItemComplementary.forEach(item => {
                if (item.selectable === true) { // if atleast one is selectable, then article can be on/off montage
                    isPossible = true;
                }
            });
        }

        return isPossible;
    }

    const isSelectable = (rowData) => {
        let offerItem = offerItemsRef.current.find(item => item.id == rowData.id);
        let isPossible = true;
        if (offerItem.selectable === true) { // if atleast one is selectable, then article can be on/off montage
            isPossible = false;
        }

        return isPossible;
    }

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        let fieldValue = { [name]: value };

        //console.log('fieldValue', fieldValue);

        setMyValues({
            ...myValues,
            ...fieldValue
        });

        validate(fieldValue);
    }

    const handleInputSonderbausteinChange = (e, rowData) => {
        let { name, value } = e.target;

        let newData = myValues.sonderkalkulationsbausteine.map(item => {
            if (item.tableData.id == rowData.tableData.id) {
                item[name] = value;
                return item;
            }

            return item;
        });

        setMyValues({
            ...myValues,
            ...{ sonderkalkulationsbausteine: newData }
        });
    }

    // fieldValues is an object of key-value pairs { [name1]: value1, [name2]: value2, ... }
    const validate = (fieldValues) => {
        let temp = {};

        let reg = {
            'capital': /^[A-Z]+$/,
            'character': /^[a-z]+$/,
            'digit': /^[0-9]+$/,
            'specialCharacter': /^[@#_%$!?-]+$/,
            'numbersOnly': /^(([0-9]+)([\.,]{1}[0-9]{1,2})?)$/
        };

        //console.log('fieldValues', fieldValues);

        if ('kalkulationsreserve' in fieldValues) {
            if (fieldValues.kalkulationsreserve.length > 0 && !reg.digit.test(fieldValues.kalkulationsreserve))
                temp.kalkulationsreserve = "Ungültige Werte. Nutzen Sie nur 0-9";
            else
                temp.kalkulationsreserve = fieldValues.kalkulationsreserve > myValues.angebotsSumme * 0.05 ? "Obergrenze max. 5% der Angebotssumme" : "";
        }

        if ('maklerprovision' in fieldValues) {
            if (fieldValues.maklerprovision.length > 0 && !reg.digit.test(fieldValues.maklerprovision))
                temp.maklerprovision = "Ungültige Werte. Nutzen Sie nur 0-9";
            else
                temp.maklerprovision = fieldValues.maklerprovision > 20000 ? "Obergrenze max. 20.000,-" : "";
        }

        if ('discountAmount' in fieldValues) {
            if (fieldValues.discountAmount.length > 1 && (!reg.digit.test(fieldValues.discountAmount) || fieldValues.discountAmount.length > 5))
                temp.discountAmount = "Ungültige Werte. Nutzen Sie nur 0-9 und bis maximal 5-Stellig";
            else
                temp.discountAmount = "";
        }

        if ('einkaufspreis' in fieldValues) {
            if (typeof temp.einkaufspreis === 'undefined') {
                temp.einkaufspreis = [];
            }

            fieldValues.einkaufspreis.map((item) => {
                let value = item.value;
                let rowId = item.rowId;
                if (value.length > 0 && !reg.numbersOnly.test(value))
                    temp.einkaufspreis.push({ rowId: rowId, error: "Ungültige Werte. Darf nur 0-9 bis maximal 2-Stellig nach Komma benutzt werden" });
                else
                    temp.einkaufspreis.push({ rowId: rowId, error: "" });
            });
        }

        if ('verkaufspreis' in fieldValues) {
            if (typeof temp.verkaufspreis === 'undefined') {
                temp.verkaufspreis = [];
            }

            fieldValues.verkaufspreis.map((item) => {
                let value = item.value;
                let rowId = item.rowId;
                if (value.length > 0 && !reg.numbersOnly.test(value))
                    temp.verkaufspreis.push({ rowId: rowId, error: "Ungültige Werte. Darf nur 0-9 bis maximal 2-Stellig nach Komma benutzt werden" });
                else
                    temp.verkaufspreis.push({ rowId: rowId, error: "" });
            });
        }

        //console.log('validate(): temp', temp);

        setMyErrors({
            ...temp
        });

        // if all values are empty, so we havent any errors, then it returns true
        return Object.values(temp).every(x => x === "");

    }

    const submit = () => {
        console.log("submit fired");

        if (myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben) {
            console.log("Save is disabled, because of offer state");
            toast.error('Das Angebot kann nicht mehr editieren werden');
            return false;
        }


        if (validate(myValues)) {
            console.log("values are valid");

            setIsSpinnerVisible(true);

            let offerItems = offerItemsRef.current.map(item => {
                let inklMontage = myValues.details.find(d => d.id == item.id).montage;
                let isOptional = myValues.details.find(d => d.id == item.id).optional;
                let itemComplementary = item.offerItemComplementary.map(ic => {
                    return {
                        Id: ic.id,
                        ParentItemId: ic.parentItemId,
                        ItemNumber: ic.itemNumber,
                        ProductConfigurationId: ic.productConfigurationId ? ic.productConfigurationId : '',
                        ShowInOffer: ic.showInOffer,
                        Include: ic.selectable ? inklMontage : ic.include // we cant change montage from not selectable article
                    }
                });
                return {
                    Id: item.id,
                    OfferId: item.offerId,
                    ItemNumber: item.itemNumber,
                    Optional: isOptional,
                    ProductConfigurationId: item.productConfigurationId ? item.productConfigurationId : '',
                    ShowInOffer: item.showInOffer,
                    OfferItemComplementary: itemComplementary,
                }
            });

            let offerItemSonder = myValues.sonderkalkulationsbausteine.map(item => {
                return {
                    Id: item.id,
                    ItemNumber: item.no,
                    ShortText: item.kurztext,
                    LongText: item.langtext,
                    CostPrice: item.einkaufspreis.toString().replaceAll(',', '.'),
                    UnitPrice: item.verkaufspreis.toString().replaceAll(',', '.'),
                }
            });

            let discounts = myValues.discounts.map(item => {
                return {
                    TblDcTypeId: item.tblDcTypeId,
                    Amount: item.amount,
                };
            });

            let currentTemplatesData = myTableRef.current.dataManager.data.filter(o => o.tableData.checked);
            let filteredTemplates = [];
            if (currentTemplatesData?.length > 0) {
                filteredTemplates = currentTemplatesData.map(d => d.templateName + d.templateFormat)
            }
            //console.log('########## filteredTemplates: ', filteredTemplates);
            //console.log('tableTemplatesData: ', tableTemplatesData);
            //console.log('myValues.templates: ', myValues.templates);

            //console.log('myTableRef.current.dataManager.data: ', myTableRef.current.dataManager.data);
            //console.log('FILTERED myTableRef.current.dataManager.data: ', myTableRef.current.dataManager.data.filter(o => o.tableData.checked));
                    // object to backend
            let dataToUpdate = {
                Id: myValues.id,
                Offerrevision: myValues.offerRevision,
                CalculationReserve: myValues.kalkulationsreserve,
                BrokerProvision: myValues.maklerprovision,
                Description: myValues.bemerkungsfeld,
                SupplyVoucherId: !isNaN(myValues.bemusterungsgutschein) ? myValues.bemusterungsgutschein : 0,
                StatusSalesForceId: !isNaN(myValues.status) ? myValues.status : 0,
                printUnitPrice: myValues.druckenEinzelpreisenCheckbox,
                printSubTotalPrice: myValues.druckenZwieschensummenCheckbox,
                printSupplyVoucher: myValues.druckenBemusterungsgutscheinCheckbox,
                StatusClosed: myValues.finalisierenCheckbox ? true : false,
                OfferStateId: myValues.finalisierenCheckbox ? OFFER_STATE_FINAL : (myValues.offerStateId == OFFER_STATE_FINAL ? OFFER_STATE_BEARBEITUNG : myValues.offerStateId),
                Signed: myValues.unterschriebenCheckbox ? true : false,
                //templates: myValues.templates,
                templates: filteredTemplates,
                OfferDiscount: discounts,
                OfferItems: offerItems,
                offerItemSonder: offerItemSonder,

            };

            console.log('dataToUpdate', dataToUpdate);

            request('updateOfferByOfferId', props.currentId, dataToUpdate).then(response => {
                const afterSaveEvents = () => {
                    setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
                    props.close();
                    toast.success('Angebot wurde erfolgreich gespeichert!');
                    props.afterSave();
                };


                if (error.length === 0 && typeof response !== 'undefined' && !myValues.unterschriebenCheckbox) {
                    // Call pdf file and open it in new browser-tab, if file was found
                    // Otherweis will be put an Error Toast and loaded list of all orders without opening new tab
                    let offerId = response?.offerId; //129;
                    let offerRev = response?.offerRevision; //27;
                    let params = {
                        revision: offerRev
                    };


                    request('getOfferPDFDocumentById', offerId, params).then(response => {
                        if (error.length === 0 && typeof response !== 'undefined') {
                            if (response.byteLength > 0) {
                                downloadFile(response, 'Angebot ' + offerId + '-' + offerRev + '.pdf');
                            } else {
                                toast.error('Die Datei ist nicht vorhanden');
                            }
                        } else {
                            toast.error('Die Datei wurde nicht gefunden');
                        }
                    })
                        .finally(() => {
                            afterSaveEvents();
                        });
                } else {
                    afterSaveEvents();
                }
            });
        } else {
            console.log("values have errors");
            toast.error('Es ist eine Fehler bei der Validierung aufgetreten');
        }
    };



    const getAusfuehrung = () => {

        var value = "";
        if (myValues.offerLivingSpacePerStoreys > 0) console.log("we have values")
        myValues.offerLivingSpacePerStoreys.map((index) => {
            if (value.length > 0) {
                value = value + "/";
            }

            value = value + index.storey.toString();
        }
        )
        return value
    }

    const renderFlache = () => {

        return <>
            {myValues.offerLivingSpacePerStoreys.map(x =>

                <Grid container spacing={3} className={styles.gridContainerRow}>
                    <Grid item xs={12}>
                        <TextField
                            label={"Wohnfläche " + x.storey}
                            value={x.area ? x.area.toFixed(2) + " m²" : ""}
                            disabled
                            className={styles.textField}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    }


    return (
        <>
            {props.isOpen === false ? '' : isDataLoaded === false || isTemplatesLoaded === false ? <Dialog
                open={props.isOpen}
                onClose={props.close}
                aria-labelledby="draggable-dialog-title"
                maxWidth='xl'
                fullWidth={false}
                disableEscapeKeyDown
            >
                <SpinnerPopover isVisible={props.isOpen && error.length === 0 && (isDataLoaded === false || isTemplatesLoaded === false)} />
            </Dialog> : <Dialog
                open={props.isOpen}
                onClose={props.close}
                aria-labelledby="draggable-dialog-title"
                maxWidth='xl'
                fullWidth={false}
                disableEscapeKeyDown
            >
                <DialogTitle id="draggable-dialog-title">
                    Angebot zu bearbeiten

                </DialogTitle>

                <DialogContent style={{ marginTop: '90px' }}>

                    <SpinnerPopover isVisible={isSpinnerVisible} />

                    <Grid container style={{ marginLeft: '10px' }}>
                        <Grid container spacing={3} style={{ position: 'fixed', marginTop: '-85px' }}>

                            <Grid item xs={2}>
                                <TextField
                                    label="Angebotsnummer"
                                    value={myValues.angebotsnummer}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <TextField
                                    label="Name 1"
                                    value={myValues.name1}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <TextField
                                    label="Name 2"
                                    value={myValues.name2}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>

                            <Grid item xs={2}>
                                <TextField
                                    label='Handelsvertretung'
                                    value={myValues.handelsvertreter}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>

                            <Grid item xs={1}>
                                <TextField
                                    label='Erstelldatum'
                                    value={formatDate(myValues.erstelldatum)}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    label='Revisionsdatum'
                                    value={myValues.offerRevisionsdatum}
                                    disabled
                                    className={styles.textField}
                                />
                            </Grid>

                        </Grid>


                        <Grid container spacing={3} className={styles.expandGrid}>

                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>Angebot (Preislistenversionsnummer: {myValues.costingVersion})</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Titel Hausvorschlag"
                                                    value={myValues.titelHausvorschlag}
                                                    disabled
                                                    className={styles.textField}
                                                    />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <TextField
                                                    label='Ersteller'
                                                    value={myValues.ersteller}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                            {/*    <FormControl className={styles.formControl} fullWidth={true}>*/}
                                            {/*        <InputLabel id="status-label-id">Phase</InputLabel>*/}
                                            {/*        <Select*/}
                                            {/*            labelId="status-label-id"*/}
                                            {/*            id="status-id"*/}
                                            {/*            name='status'*/}
                                            {/*            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}*/}
                                            {/*            value={myValues.status}*/}
                                            {/*            onChange={handleInputChange}*/}
                                            {/*        >*/}
                                            {/*            */}{/*<MenuItem key={0} value={0}>*/}{/*
                                            */}{/*                    -*/}{/*
                                            */}{/*                </MenuItem>*/}
                                            {/*                {props.allSaleForceStatuses.filter(s => s.tblDataAreaId === myMandantId).map((status) => (*/}
                                            {/*                <MenuItem key={status.id} value={status.id}>*/}
                                            {/*                    {status.description}*/}
                                            {/*                </MenuItem>*/}
                                            {/*            ))}*/}
                                            {/*        </Select>*/}
                                            {/*    </FormControl>*/}
                                                <TextField
                                                    label='Phase'
                                                    value={props.allSaleForceStatuses
                                                        .filter(s => s.tblDataAreaId === myMandantId && s.id === myValues.status)
                                                        .map((status) => (status.description))}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>

                                            <Grid item xs={1}>
                                                <Box m={1.5} >
                                                    <FormControlLabel
                                                        value="true"
                                                        control={<Checkbox
                                                            name='finalisierenCheckbox'
                                                            disabled={myValues.unterschriebenCheckbox || myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                            checked={myValues.finalisierenCheckbox || myValues.isUntergeschrieben}
                                                            onChange={(e) => {
                                                                setMyValues({
                                                                    ...myValues,
                                                                    ...{ [e.target.name]: e.target.checked }
                                                                });
                                                            }}
                                                        />}
                                                        label="Finalisieren"
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={2}>
                                                <Box m={1.5} >
                                                    <FormControlLabel
                                                        value="true"
                                                        control={<Checkbox
                                                            name='unterschriebenCheckbox'
                                                            disabled={!myValues.finalisierenCheckbox || !myValues.isFinal || myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                            checked={myValues.unterschriebenCheckbox}
                                                            onChange={(e) => {
                                                                setMyValues({
                                                                    ...myValues,
                                                                    ...{ [e.target.name]: e.target.checked }
                                                                });
                                                            }}
                                                        />}
                                                        label="Unterschrieben"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label='Bemerkungsfeld'
                                                    name='bemerkungsfeld'
                                                    disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                    value={myValues.bemerkungsfeld}
                                                    //multiline
                                                    maxRows={3}
                                                    className={styles.textField}
                                                    onChange={handleInputChange}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={3} className={styles.gridContainerRow} style={{ marginTop: '20px' }}>

                                            <MaterialTable
                                                title='Kunden'
                                                icons={MaterialTableIcons}
                                                columns={[
                                                    { title: 'Name', field: 'name', width: '20%' },
                                                    { title: 'E-mailadresse', field: 'email', width: '20%' },
                                                    { title: 'Telefonnummer', field: 'telephone', width: '15%' },
                                                    { title: 'Anschrift', field: 'address', width: '45%' },
                                                ]}
                                                data={myValues.clients}
                                                style={{
                                                    whiteSpace: "nowrap",
                                                    wordWrap: "break-word",
                                                    width: '100%',
                                                }}
                                                options={{
                                                    selection: false,
                                                    filtering: false,
                                                    search: false,
                                                    paging: false,
                                                    headerStyle: {
                                                        position: 'sticky',
                                                        fontSize: '1.2em',
                                                        padding: '15px',
                                                        backgroundColor: '#f8c7a4',
                                                    },
                                                    rowStyle: {
                                                        whiteSpace: 'pre-wrap',
                                                        backgroundColor: '#eee',
                                                    },
                                                }}
                                            />

                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container className={styles.expandGrid}>

                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                >
                                    <Typography>Hausangaben</Typography>

                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container xs={4} spacing={1} style={{ width: '100%', margin: '2px' }}>
                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Ausbauart"
                                                    value={myValues.haustype}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={4} className={styles.gridContainerRow}>
                                            <Grid item xs={6}>
                                                <TextField
                                                    label="Bauort"
                                                    value={myValues.bauort}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                    <Grid container xs={4} spacing={1} style={{ width: '100%', margin: '2px' }}>
                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Geschoß-Ausführung"
                                                    value={getAusfuehrung()}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={styles.gridContainerRow}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Dachneigung"
                                                    value={myValues.dachneigung.toFixed(0) + "°"}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container className={styles.gridContainerRow}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="Kniestock "
                                                    value={myValues.kniestock.toFixed(2) + " m"}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container xs={4} spacing={1} style={{ width: '100%', margin: '2px' }}>
                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    label="bebaute Fläche"
                                                        value={myValues.bebauteFlaeche.toFixed(2) + " m²"}
                                                    disabled
                                                    className={styles.textField}
                                                />
                                            </Grid>
                                        </Grid>
                                        {renderFlache()}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>

                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                >
                                    <Typography>Rabatte, ohne MwSt. </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                        {myValues.discountsAll.map((type) => {
                                            let currDiscount = myValues.discounts.filter(d => d.tblDcTypeId == type.id);

                                            return (<>

                                                <Grid item xs={6}>
                                                    <Box m={1.5} >
                                                        <FormControlLabel
                                                            value="true"
                                                            control={<Checkbox
                                                                name='discounts'
                                                                disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || type.disabled}
                                                                checked={currDiscount.length > 0 ? true : false}
                                                                onChange={(e) => {
                                                                    let newDiscounts = myValues.discounts;
                                                                    if (!e.target.checked) {
                                                                        newDiscounts = myValues.discounts.filter(d => d.tblDcTypeId != type.id);
                                                                    } else {
                                                                        newDiscounts.push({
                                                                            tblDcTypeId: type.id,
                                                                            amount: type.dcamount
                                                                        });
                                                                    }
                                                                    setMyValues({
                                                                        ...myValues,
                                                                        ...{ discounts: newDiscounts }
                                                                    });
                                                                }}
                                                            />}
                                                            label={
                                                                <>
                                                                    {type.dctype != 'ABS' ? type.dcamount : <TextField // IMPORTANT (!) - 'ABS' means absolut, that must be named same to backend
                                                                        name='discountAmount'
                                                                        className={styles.discountTextField}
                                                                        disabled={currDiscount.length < 1 || myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || type.disabled}
                                                                        value={currDiscount.length < 1 ? '' : currDiscount[0]?.amount}
                                                                        onChange={(e) => {
                                                                            let newAmount = e.target.value;
                                                                            let newDiscounts = myValues.discounts.map(d => { return d.tblDcTypeId == type.id ? { tblDcTypeId: d.tblDcTypeId, amount: newAmount } : d });
                                                                            //console.log('newDiscounts', newDiscounts);
                                                                            setMyValues({
                                                                                ...myValues,
                                                                                ...{ discounts: newDiscounts }
                                                                            });

                                                                            validate({ [e.target.name]: newAmount });
                                                                        }}
                                                                        {...(myErrors.discountAmount && { error: true, helperText: myErrors.discountAmount })}
                                                                    />} {type.unitSymbol} ({type.dcdescription})
                                                                </>
                                                            }
                                                        />
                                                    </Box>
                                                </Grid>
                                            </>)
                                        })}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>

                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                        <Typography>Zusatzparameter, ohne MwSt.</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                        <Grid container spacing={3} className={styles.gridContainerRow}>
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Kalkulationsreserve (€)"
                                                    name="kalkulationsreserve"
                                                    disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                    value={myValues.kalkulationsreserve}
                                                    type="text"
                                                    className={styles.textField}
                                                    onChange={handleInputChange}
                                                    {...(myErrors.kalkulationsreserve && { error: true, helperText: myErrors.kalkulationsreserve })}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    label="Maklerprovision (€)"
                                                    name="maklerprovision"
                                                    disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                    value={myValues.maklerprovision}
                                                    type="number"
                                                    className={styles.textField}
                                                    onChange={handleInputChange}
                                                    {...(myErrors.maklerprovision && { error: true, helperText: myErrors.maklerprovision })}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControl className={styles.formControl} fullWidth={true}>
                                                    <InputLabel id="bemusterungsgutschein-label-id">Bemusterungsgutschein (€)</InputLabel>
                                                    <Select
                                                        labelId="bemusterungsgutschein-label-id"
                                                        id="bemusterungsgutschein-id"
                                                        name='bemusterungsgutschein'
                                                        disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                        value={myValues.bemusterungsgutschein}
                                                        onChange={handleInputChange}
                                                    >
                                                        {props.allSupplyVouchers.filter(sv =>sv.tblDataAreaId === myMandantId).map((voucher) => (
                                                            <MenuItem key={voucher.id} value={voucher.id}>
                                                                {voucher.amount} {voucher.unitSymbol} ({voucher.description})
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Box m={1.5} >
                                                    <FormControlLabel
                                                        value="true"
                                                        control={<Checkbox
                                                            name='druckenBemusterungsgutscheinCheckbox'
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                                            checked={myValues.druckenBemusterungsgutscheinCheckbox}
                                                            onChange={(e) => {
                                                                setMyValues({
                                                                    ...myValues,
                                                                    ...{ [e.target.name]: e.target.checked }
                                                                });
                                                            }}
                                                        />}
                                                        label="Bemusterungsgutschein andrucken"
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>

                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                >
                                    <Typography>Vertragsunterlagen</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                            <MaterialTable
                                                tableRef={myTableRef}
                                            icons={MaterialTableIcons}
                                            columns={[
                                                { title: 'Datei', field: 'templateName', render: rowData => { return rowData.templateName + rowData.templateFormat } },
                                                { title: 'Dateinameerweiterung', field: 'templateFormat', hidden: true },
                                                { title: 'Position', field: 'templateOrder', defaultSort: 'asc', hidden: true },
                                            ]}
                                            //data={tableTemplatesData}
                                            data={
                                                tableTemplatesData?.map(d => {
                                                    let result = {
                                                        ...d,
                                                        tableData: {
                                                            checked: myValues.templates.includes(d.templateName + d.templateFormat),
                                                        },
                                                    };
                                                    return result;
                                                }) || []
                                            }
                                            style={{
                                                whiteSpace: "nowrap",
                                                wordWrap: "break-word",
                                                width: '100%',
                                            }}
                                            options={{
                                                selection: true,
                                                filtering: false,
                                                search: false,
                                                paging: false,
                                                showTitle: false,
                                                toolbar: false,
                                                headerStyle: {
                                                    position: 'sticky',
                                                    fontSize: '1.2em',
                                                    padding: '15px',
                                                    backgroundColor: '#f8c7a4',
                                                },
                                                rowStyle: {
                                                    whiteSpace: 'pre-wrap',
                                                    backgroundColor: '#eee',
                                                },
                                                selectionProps: rowData => {
                                                    rowData.tableData.disabled = myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben;
                                                    return {
                                                        disabled: myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben
                                                    }
                                                },
                                            }}
                                            onSelectionChange={(rows) => {
                                                //console.log('selected templates', rows);
                                                if (!myValues.isAbgelehnt && !myValues.isStorno && !myValues.isUntergeschrieben) {
                                                    setMyValues({
                                                        ...myValues,
                                                        templates: rows.map(row => row.templateName + row.templateFormat)
                                                    });
                                                } else {
                                                    console.log('selected templates can not be edited because of offer state');
                                                }
                                            }}
                                        />

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>
                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5a-content"
                                    id="panel5a-header"
                                >
                                    <Typography>Basispreise (Gesamt: {myValues.workingCost}) (IW = {myValues.iw}; AW = {myValues.aw}; DE = {myValues.de}; DA = {myValues.da}), ohne MwSt.</Typography>
                                </AccordionSummary>
                                {!currentUserState.can(ANGEBOT_FORM_BASISPREISE) ? '' : <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                        <MaterialTable
                                            icons={MaterialTableIcons}
                                            columns={[
                                                { title: 'ArtikelNo.', field: 'no', width: '10%' },
                                                { title: 'Beschreibung', field: 'name' },
                                                { title: 'Produktkonfigurationsid', field: 'productConfigurationId', hidden: true, byCsvExportForceInclude: true },
                                                { title: 'Summe, ohne MwSt.', field: 'sumPrice', width: '10%', byCsvExportFormattedAs: 'number', type: 'currency', currencySetting: { locale: 'de-DE', currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2 } },
                                            ]}
                                            data={myValues.basispreise}
                                            style={{
                                                whiteSpace: "nowrap",
                                                wordWrap: "break-word",
                                                width: '100%',
                                            }}
                                            options={{
                                                selection: false,
                                                filtering: false,
                                                exportButton: !myValues.isAbgelehnt && !myValues.isStorno && !myValues.isUntergeschrieben && currentUserState.can(ANGEBOT_FORM_DOWNLOAD_CSV),
                                                exportAllData: true,
                                                exportDelimiter: ';',
                                                exportFileName: 'Basispreise ' + myValues.angebotsnummer,
                                                exportCsv: (columns, data) => { handleExportCSV('Basispreise ' + myValues.angebotsnummer, data, columns) },
                                                search: false,
                                                paging: false,
                                                showTitle: false,
                                                //toolbar: false,
                                                headerStyle: {
                                                    position: 'sticky',
                                                    fontSize: '1.2em',
                                                    padding: '15px',
                                                    backgroundColor: '#f8c7a4',
                                                },
                                                //maxBodyHeight: 'calc(100vh - 216px)',
                                                rowStyle: {
                                                    whiteSpace: 'pre-wrap',
                                                    backgroundColor: '#eee',
                                                },
                                            }}
                                        />

                                    </Grid>
                                </AccordionDetails>}
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>
                            <Accordion style={{ width: '100%' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel5a-content"
                                    id="panel5a-header"
                                >
                                    <Typography>Sonderkalkulationsbausteine</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={1} style={{ width: '100%', margin: '2px' }}>

                                        <MaterialTable
                                            icons={MaterialTableIcons}
                                            columns={[
                                                { title: 'id', field: 'id', hidden: true },
                                                {
                                                    title: 'ArtikelNo.', field: 'no', width: '10%',
                                                    render: rowData => {
                                                        return <Select
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE)}
                                                            value={rowData.no || ''}
                                                            onChange={(e) => {
                                                                //let currData = props.allSonderProducts.find(item => item.itemNumber == e.target.value) || {};
                                                                let currData = mySonderProducts.find(item => item.itemNumber == e.target.value) || {};
                                                                console.log('currDataSonderProducts', currData);
                                                                let newData = myValues.sonderkalkulationsbausteine.map(item => {
                                                                    if (item.tableData.id == rowData.tableData.id) {
                                                                        return {
                                                                            id: currData.id || 0,
                                                                            no: currData.itemNumber || '',
                                                                            kurztext: currData.shortText || '',
                                                                            langtext: currData.longText || '',
                                                                            einkaufspreis: currData.costPrice || 0,
                                                                            verkaufspreis: currData.unitPrice || 0,
                                                                            tableData: rowData.tableData
                                                                        };
                                                                    }

                                                                    return item;
                                                                });

                                                                setMyValues({
                                                                    ...myValues,
                                                                    ...{ sonderkalkulationsbausteine: newData }
                                                                });
                                                            }}
                                                        >
                                                            <MenuItem key='' value=''>
                                                                -
                                                            </MenuItem>
                                                            {mySonderProducts.map((item) => (
                                                                <MenuItem key={item.itemNumber} value={item.itemNumber}>
                                                                    {item.itemNumber}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    }
                                                },
                                                {
                                                    title: 'Kurztext', field: 'kurztext',
                                                    render: rowData => {
                                                        return <TextField
                                                            name='kurztext'
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE)}
                                                            value={rowData.kurztext}
                                                            //value={currItem.kurztext || ''}
                                                            className={styles.textField}
                                                            onChange={(e) => {
                                                                handleInputSonderbausteinChange(e, rowData);
                                                            }}
                                                        />
                                                    }
                                                },
                                                {
                                                    title: 'Langtext', field: 'langtext',
                                                    render: rowData => {
                                                        return <TextField
                                                            name='langtext'
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE)}
                                                            value={rowData.langtext}
                                                            className={styles.textField}
                                                            onChange={(e) => {
                                                                handleInputSonderbausteinChange(e, rowData);
                                                            }}
                                                        />
                                                    }
                                                },
                                                {
                                                    title: 'Einkaufspreis', field: 'einkaufspreis', width: '10%',
                                                    type: 'currency', currencySetting: {
                                                        locale: 'de-DE', currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2
                                                    },
                                                    render: rowData => {
                                                        let rowId = rowData.tableData.id;
                                                        let error = (typeof myErrors.einkaufspreis !== 'undefined' && myErrors.einkaufspreis.find((val) => val.rowId == rowId)?.error) || '';
                                                        return <TextField
                                                            name='einkaufspreis'
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE)}
                                                            value={rowData.einkaufspreis}
                                                            className={styles.textField}
                                                            onChange={(e) => {
                                                                handleInputSonderbausteinChange(e, rowData);
                                                                validate({ 'einkaufspreis': [{ rowId: rowId, value: e.target.value }] });
                                                            }}
                                                            {...(myErrors.einkaufspreis && error && { error: true, helperText: error })}
                                                        />
                                                    }
                                                },
                                                {
                                                    title: 'Verkaufspreis', field: 'verkaufspreis', width: '10%',
                                                    type: 'currency', currencySetting: {
                                                        locale: 'de-DE', currencyCode: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 2
                                                    },
                                                    render: rowData => {
                                                        let rowId = rowData.tableData.id;
                                                        let error = (typeof myErrors.verkaufspreis !== 'undefined' && myErrors.verkaufspreis.find((val) => val.rowId == rowId)?.error) || '';
                                                        return <TextField
                                                            name='verkaufspreis'
                                                            disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE)}
                                                            value={rowData.verkaufspreis}
                                                            className={styles.textField}
                                                            onChange={(e) => {
                                                                handleInputSonderbausteinChange(e, rowData);
                                                                validate({ 'verkaufspreis': [{ rowId: rowId, value: e.target.value }] });
                                                            }}
                                                            {...(myErrors.verkaufspreis && error && { error: true, helperText: error })}
                                                        />
                                                    }
                                                },
                                            ]}
                                            data={myValues.sonderkalkulationsbausteine}
                                            actions={[
                                                {
                                                    position: "toolbar",
                                                    icon: () => <QueueIcon color="danger" />,
                                                    tooltip: 'Einfügen',
                                                    hidden: myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE),
                                                    onClick: (e, rowData) => {
                                                        let newData = myValues.sonderkalkulationsbausteine;
                                                        newData.push({ id: 0, no: '', kurztext: '', langtext: '', einkaufspreis: 0, verkaufspreis: 0 });
                                                        setMyValues({
                                                            ...myValues,
                                                            ...{ sonderkalkulationsbausteine: newData }
                                                        })
                                                    }
                                                },
                                                (rowData) => ({
                                                    disabled: !currentUserState.can(ANGEBOT_FORM_SONDERKALKULATIONSBAUSTEINE) ? true : false,
                                                    icon: () => <ClearIcon color="primary" />,
                                                    tooltip: 'Eintrag löschen',
                                                    onClick: (e, rowData) => {
                                                        let newData = myValues.sonderkalkulationsbausteine.filter(item => item.tableData.id != rowData.tableData.id);
                                                        setMyValues({
                                                            ...myValues,
                                                            ...{ sonderkalkulationsbausteine: newData }
                                                        });
                                                    }
                                                }),
                                            ]}
                                            style={{
                                                whiteSpace: "nowrap",
                                                wordWrap: "break-word",
                                                width: '100%',
                                            }}
                                            options={{
                                                selection: false,
                                                filtering: false,
                                                search: false,
                                                paging: false,
                                                showTitle: false,
                                                //toolbar: false,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    position: 'sticky',
                                                    fontSize: '1.2em',
                                                    padding: '15px',
                                                    backgroundColor: '#f8c7a4',
                                                },
                                                //maxBodyHeight: 'calc(100vh - 216px)',
                                                rowStyle: {
                                                    whiteSpace: 'pre-wrap',
                                                    backgroundColor: '#eee',
                                                },
                                            }}
                                            localization={{
                                                body: {
                                                    emptyDataSourceMessage: 'Keine Daten vorhanden!'
                                                },
                                                header: {
                                                    actions: 'Aktionen'
                                                },
                                            }}
                                        />

                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        </Grid>

                        <Grid container spacing={3} className={styles.expandGrid}>
                            <Box m={1.5} >
                                <FormControlLabel
                                    value="true"
                                    control={<Checkbox
                                        name='anzeigeEinzelpreisenCheckbox'
                                        checked={myValues.anzeigeEinzelpreisenCheckbox}
                                        disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                        onChange={(e) => {
                                            setMyValues({
                                                ...myValues,
                                                ...{ [e.target.name]: e.target.checked }
                                            });
                                        }}
                                    />}
                                    label="Anzeige Einzelpreise"
                                />
                            </Box>

                            <MaterialTable
                                title="Details, ohne MwSt."
                                columns={detailsTableColumns}
                                data={
                                    myValues.details?.map(d => {
                                        let result = {
                                            ...d,
                                            tableData: {
                                                checked: d.optional,
                                                disabled: myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || isSelectable(d)
                                            },
                                        };
                                        return result;
                                    }) || []
                                }
                                actions={[
                                    {
                                        // this action needs to keep export button during some rows are selcted
                                        // because by default it takes for export only selected rows or even hide the export button
                                        position: "toolbarOnSelect",
                                        icon: () => <VerticalAlignBottomIcon color="danger" />,
                                        tooltip: 'Export as CSV',
                                        hidden: myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_DOWNLOAD_CSV),
                                        onClick: (e, rowData) => {
                                            // put here from myState all detail to export ALL data, and not only selected
                                            handleExportCSV('Details ' + myValues.angebotsnummer, myValues.details, detailsTableColumns);
                                        }
                                    }
                                ]}
                                options={{
                                    selection: true,
                                    showSelectAllCheckbox: false,
                                    showTextRowsSelected: false,
                                    filtering: true,
                                    draggable: false,
                                    exportButton: !myValues.isAbgelehnt && !myValues.isStorno && !myValues.isUntergeschrieben && currentUserState.can(ANGEBOT_FORM_DOWNLOAD_CSV),
                                    exportAllData: true,
                                    exportFileName: 'Details ' + myValues.angebotsnummer,
                                    exportDelimiter: ';',
                                    exportCsv: (columns, data) => { handleExportCSV('Details ' + myValues.angebotsnummer, data, columns) },
                                    rowStyle: {
                                        whiteSpace: 'pre-wrap',
                                        wordWrap: "break-word",
                                        backgroundColor: '#eee',
                                    },
                                    headerStyle: {
                                        position: 'sticky',
                                        fontSize: '1.2em',
                                        padding: '10px',
                                        backgroundColor: '#f8c7a4',
                                    },
                                    tableLayout: "fixed",
                                    selectionProps: rowData => {
                                        rowData.tableData.disabled = myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || isSelectable(rowData);
                                        rowData.tableData.title = 'Optional';
                                        return {
                                            disabled: myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || isSelectable(rowData),
                                            title: 'Optional'
                                        }
                                    },
                                }}
                                onSelectionChange={(rows) => {
                                    if (!myValues.isAbgelehnt) {
                                        changeOptional(rows);
                                    } else {
                                        console.log('selected optional items can not be edited because of offer state');
                                    }
                                }}
                                icons={MaterialTableIcons}
                                detailPanel={[
                                    rowData => ({
                                        disabled: rowData.optional || offerItemsRef.current.find(item => item.id == rowData.id)?.offerItemDiscount.length < 1,
                                        tooltip: 'Rabatte anzeigen',
                                        render: rowData => {
                                            let offerItemDiscounts = offerItemsRef.current.find(item => item.id == rowData.id)?.offerItemDiscount || [];

                                            return (
                                                <div
                                                    style={{
                                                        fontSize: '15px',
                                                        padding: '20px',
                                                        paddingLeft: '60px',
                                                        whiteSpace: 'pre-line' // this rule is important to make a correct join strings with \n
                                                    }}
                                                >
                                                    {offerItemDiscounts.map(d => { return d.dcAmount + ' ' + d.unitSymbol + ' (' + d.description + ')' }).join('\n')}
                                                </div>
                                            )
                                        },
                                    }),
                                ]}
                            />

                        </Grid>

                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Box m={1.5} >
                        <FormControlLabel
                            value="true"
                            control={<Checkbox
                                name='druckenZwieschensummenCheckbox'
                                disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}
                                checked={myValues.druckenZwieschensummenCheckbox}
                                onChange={(e) => {
                                    setMyValues({
                                        ...myValues,
                                        ...{ [e.target.name]: e.target.checked }
                                    });
                                }}
                            />}
                            label="Zwischensummen andrucken"
                        />
                    </Box>
                    <Box m={1.5} >
                        <FormControlLabel
                            value="true"
                            control={<Checkbox
                                name='druckenEinzelpreisenCheckbox'
                                disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben || !currentUserState.can(ANGEBOT_FORM_EINZELPREISE_ANDRUCKEN)}
                                checked={myValues.druckenEinzelpreisenCheckbox}
                                onChange={(e) => {
                                    setMyValues({
                                        ...myValues,
                                        ...{ [e.target.name]: e.target.checked }
                                    });
                                }}
                            />}
                            label="Einzelpreis andrucken"
                        />
                    </Box>
                    <Button onClick={submit} color="primary" disabled={myValues.isAbgelehnt || myValues.isStorno || myValues.isUntergeschrieben}>
                        Speichern und erstellen
                    </Button>
                    <Button onClick={props.close} color="primary">
                        Abbrechen
                    </Button>

                </DialogActions>
            </Dialog>
            }
        </>
    );
}