import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles({
    popover: {
        boxShadow: 'none',
        background: 'radial-gradient(circle at center, transparent, transparent)',
        borderRadius: '50%'
    },
})

export default function SpinnerPopover(props) {

    const classes = useStyles();

    return (
        <Popover
            open={props.isVisible}
            anchorReference='anchorPosition'
            anchorPosition={{
                left: window.innerWidth / 2,
                top: window.innerHeight / 2
            }}
            transformOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}

            classes={{ paper: classes.popover }}
        >
            <CircularProgress
                disableShrink
                style={{ margin: 20, color: '#ee741d' }}
            />
        </Popover>

    );

}