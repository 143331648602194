import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import haasLogo from '../../images/haas_logo.svg';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: `100%`,
            paddingLeft: 300,
            paddingRight: 300,
        },

        bigLogoContainer: {
            marginTop: '100px',
        },

        bigLogo: {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '700px'
        },

        smallLogo: {
            float: 'right',
            height: '140px',
            marginRight: '-265px'
        },

        title: {
            width: '100%',
            textAlign: 'center',
            fontFamily: 'sans-serif',
            fontSize: '3.5rem',
        },

        welcome: {
            width: '100%',
            textAlign: 'center',
            fontFamily: 'sans-serif',
            fontSize: '1.5rem',
            marginBottom: '20px'
        }
    }));

export function Home () {

    const styles = useStyles();
    
    return (
        <div className={styles.container}>
            <p className={styles.title}>

            </p>
            <p className={styles.welcome}>
                
            </p>
            <div className={styles.bigLogoContainer}>
                <img className={styles.bigLogo} src={haasLogo} />
            </div>
        </div>
    );
}
