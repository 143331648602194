import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Paper, ThemeProvider } from '@material-ui/core';
import MaterialTable from 'material-table';
import MaterialTableIcons from '../../components/MaterialTableIcons';
import { makeStyles, createStyles, Grid } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from '@material-ui/icons/GetApp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import QueueIcon from '@material-ui/icons/Queue';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';

import { formatter, downloadFile, formatDate } from '../../helper';
import toast from 'react-hot-toast';

import { User, ANGEBOTE, ANGEBOTE_MITARBEITER, ANGEBOT_EXPORT_TO_INCREASE_BUTTON, ANGEBOT_FORM_OPEN, ANGEBOT_FORM_EMAIL, ANGEBOT_FORM_DOWNLOAD, ANGEBOT_ATTACHMENTS_PANEL } from '../../auth/User';

import AngebotForm from './AngebotForm';
import AngebotAttachments from './AngebotAttachments';
import SpinnerPopover from '../../components/SpinnerPopover';

import { useMakeRequest } from '../../api/Requests';

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            margin: '25px',
            padding: '25px',

        },
    }));

const theme = createMuiTheme({
    overrides: {
        MuiTableCell: {
            root: {
                padding: '5px 2px',

                /* for breaking long words */
                'overflow-wrap': 'break-word',
                'word-wrap': 'break-word',
                '-ms-word-break': 'break-all',
                'word-break': 'break-all',
                'word-break': 'break-word',
                /* Adds a hyphen where the word breaks, if supported (No Blink) */
                //'-ms-hyphens': 'auto',
                //'-moz-hyphens': 'auto',
                //'-webkit-hyphens': 'auto',
                //'hyphens': 'auto',
            },
        },
    },
});

const initialAttachmentTableState = {
    isLoading: false,
    data: []
};

export function Angebote({ ...props }) {
    const styles = useStyles();
    const [tableData, setTableData] = useState([]);
    const attachmentTableState = useRef(initialAttachmentTableState);
    const myTableRef = useRef(null);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [attachmentsDialogVisible, setAttachmentsDialogVisible] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [offerById, setOfferById] = useState(0);
    const [exportButtonColor, setExportButtonColor] = useState({});
    // initialize custom API useEffect to make requests
    const { request, error } = useMakeRequest();

    // initialize custom User Component to check permisions
    const currentUserState = User();

    // is a reference which contains all clients by customerId
    const clientsByCustomer = useRef([]);
    // is a reference which contains all supplyVouchers
    const allSupplyVouchers = useRef([]);
    // is a reference which contains all SaleForce Statuses
    const allSaleForceStatuses = useRef([]);
    // is a reference which contains all offer states
    const allOfferStates = useRef([]);
    // is a reference which contains all sonder products
    const allSonderProducts = useRef([]);
    // is a reference which contains current offer Id for edit form
    const currentId = useRef(0);
    // is a reference which contains props from current opened attachment panel
    const currentAttachmentPanel = useRef({});

    const isUserHasPermissionToThisPage = props?.includeReportsMe ? currentUserState.can(ANGEBOTE_MITARBEITER) : currentUserState.can(ANGEBOTE)

    useEffect(() => {
        if (!currentUserState.isLoading && !isUserHasPermissionToThisPage) {
            toast.error('403 Zugriff verweigert');
        } else if (isUserHasPermissionToThisPage) {
            loadTableData();
            console.log('is initialized');

            // if we have id parameter, then checkt it and set to state
            let params = (new URLSearchParams(window.location.search));
            if (params.has("id") === true) {
                let paramId = params.get("id");
                //setOfferById(Number(paramId) || 0);
                setOfferById(Number(paramId));
            }
            console.log("OfferById: " + offerById);
        }
    }, [currentUserState.isLoading]);

    useEffect(() => {
        if (initialized) {
            console.log("useEffect 3: initialized are true");

            // if we have ID from URL, then open Edit Modal
            if (offerById > 0) {
                // find necessary entity or set empty object in right type
                let offerRow = tableData.find((sp) => (sp.id === offerById)) || {};
                // open window to edit if row was found
                if (offerRow && Object.keys(offerRow).length !== 0) {
                    editAngebot(offerRow);
                }
                // set to default, it prevents this action fo future till refresh whole site
                setOfferById(0);
            }
        }
    }, [initialized]);

    const loadTableData = () => {
        setIsSpinnerVisible(true);

        request('getAllCustomer', 0, { includeReportsMe: props?.includeReportsMe ? true : false }).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                console.log('response for request(\'getAllCustomers\')', response);
                let preparedData = [];
                let defaulExportColorData = {};

                // save additional data to references
                allSupplyVouchers.current = response.supplyVouchers;
                allSaleForceStatuses.current = response.commonOfferStages;
                allOfferStates.current = response.offerState;
                allSonderProducts.current = response.sonderProducts;

                if (response.customers.length > 0) {
                    response.customers.forEach(customer => {
                        // save customer clients by customerId to global array
                        clientsByCustomer.current.push({
                            customerId: customer.customerId,
                            clients: customer.customerClients
                        });

                        // gets names of clients
                        let getClientEmail = (clientModel) => {
                            return (clientModel.email ? clientModel.email : '');
                        };
                        // gets names of clients
                        let getClientName = (clientModel) => {
                            return (clientModel.namePrefix && clientModel.namePrefix.length > 0 ? clientModel.namePrefix + ' ' : '') + (clientModel.firstName && clientModel.firstName.length > 0 ? clientModel.firstName + ' ' : '') + (clientModel.lastName && clientModel.lastName.length > 0 ? clientModel.lastName : '');
                        };
                        let customerClients = '';
                        let customerEmail = '';
                        let primaryClients = customer.customerClients.filter(clients => clients.primary == true);

                        if (primaryClients.length > 0) { // if we have found primary, then put only all primary
                            customerClients = primaryClients.map(clients => {
                                return getClientName(clients);
                            }).join(', ');
                            customerEmail = getClientEmail(primaryClients[0]);
                        } else if (customer.customerClients.length > 0) { // else puts only first name from all clients
                            customerClients = getClientName(customer.customerClients[0]);
                            customerEmail = getClientEmail(customer.customerClients[0]);
                        }

                        // puts current customer
                        preparedData.push({
                            id: customer.customerId,
                            key: customer.customerId,
                            lvl: 1,
                            parentId: '0',
                            name: customerClients,
                            Emailname: customerEmail,
                            handelsvertreter: customer.assignedTo,
                            projektNummer: '',
                            hasProjektNummer: false,
                            titelHausvorschlag: '',
                            status: '',
                            datum: '',
                            zeit: '',
                            angebot: '',
                            houseConfigModelId: '',
                            revision: '',
                            preis: '',
                            bemerkungsfeld: '',
                            D365Status: '',
                        });

                        // puts offers to current customer
                        if (customer && customer.offers && Object.keys(customer.offers).length !== 0) {
                            // get unique offers by modelId to bild second lvl of tree view
                            customer.offers
                                .filter((value, index, self) => self.map(x => x.offerNumber).indexOf(value.offerNumber) == index || self.map(x => x.houseConfigModelId).indexOf(value.houseConfigModelId) == index)
                                .forEach(offers => {
                                    preparedData.push({
                                        id: offers.customerId + ":" + offers.offerNumber + ":" + offers.houseConfigModelId,
                                        key: offers.customerId + ":" + offers.offerNumber + ":" + offers.houseConfigModelId,
                                        lvl: 2,
                                        parentId: offers.customerId,
                                        name: '',
                                        Emailname: customerEmail,
                                        handelsvertreter: '',
                                        projektNummer: offers.projectNumber ? offers.projectNumber : '',
                                        hasProjektNummer: offers.projectNumber ? true : false,
                                        titelHausvorschlag: offers.houseTitle,
                                        status: '',
                                        //datum:  dateTimeFullFormtGermanFormat(offers.offerDate),
                                        datum: offers.offerDate,
                                        //datum: dateFullFormt(offers.offerDate),
                                        zeit: '', //timeFullFormt(offers.offerDate),
                                        angebot: offers.offerNumber,
                                        houseConfigModelId: offers.houseConfigModelId,
                                        revision: '',
                                        preis: '',
                                        bemerkungsfeld: '',
                                        D365Status: '',
                                    });
                                });

                            customer.offers.forEach(offers => {
                                let offerId = offers.id;
                                defaulExportColorData[offerId] = 'primary';

                                preparedData.push({
                                    id: offers.id,
                                    key: offers.id,
                                    lvl: 3,
                                    parentId: offers.customerId + ":" + offers.offerNumber + ":" + offers.houseConfigModelId,
                                    name: '',
                                    Emailname: customerEmail,
                                    handelsvertreter: '',
                                    projektNummer: '',
                                    hasProjektNummer: offers.projectNumber ? true : false,
                                    titelHausvorschlag: '',
                                    status: offers.offerState,
                                    //datum: dateFullFormt(offers.createdAt),
                                    datum: offers.createdAt,
                                    zeit: timeFullFormt(offers.createdAt),
                                    angebot: '',
                                    houseConfigModelId: '',
                                    revision: offers.offerRevision,
                                    preis: formatter.format(offers.price),
                                    bemerkungsfeld: offers.description,
                                    D365Status: offers.d365status,
                                });
                            });
                        }
                    });
                }

                //console.log('preparedData', preparedData);
                setExportButtonColor(defaulExportColorData);
                setTableData(preparedData);
                setInitialized(true);
                console.log('is initialized: ' + initialized);

            }
        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
        });
    }

    const dateFullFormt = (givenDate) => {

        var year = new Date(givenDate).getFullYear();
        var month = new Date(givenDate).getMonth();
        var day = new Date(givenDate).getDate();

        //let date = new Date(year, month, day).toLocaleDateString("de-DE", "yyyy.MM.dd hh:mm")
        //let time = new Date(givenDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
        //return `${date} ${time}`
        //return new Date(givenDate).toLocaleDateString("de-DE", { hour12: false, day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false }).replace(',', '')
        return new Date(year, month, day).toLocaleDateString([], { day: '2-digit', month: '2-digit', year: 'numeric'})

    }

    const timeFullFormt = (givenDate) => {

        //var year = new Date(givenDate).getFullYear();
        //var month = new Date(givenDate).getMonth();
        //var day = new Date(givenDate).getDate();

        if (givenDate !== '') { 
        //let date = new Date(year, month, day).toLocaleDateString("de-DE", "yyyy.MM.dd hh:mm")
        //let time = new Date(givenDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
        //return `${date} ${time}`
            return new Date(givenDate).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });
        } else
            return new Date(0,0,0).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit", hour12: false });

    }

    const dateTimeFullFormtGermanFormat = (givenDate) => {

        var year = new Date(givenDate).getFullYear();
        var month = new Date(givenDate).getMonth();
        var day = new Date(givenDate).getDate();

        return new Date(givenDate).toLocaleDateString("de-DE", { day: '2-digit', month: '2-digit', year: 'numeric' })
       // let date = new Date(year, month, day)
       // return `${date}`

    }
    const downloadAngebot = (rowData) => {
        if (rowData.lvl !== 3) {
            console.log('Something wrong...');
            return;
        }

        setIsSpinnerVisible(true);
        let params = {
            revision: rowData.revision
        };

        request('getOfferPDFDocumentById', rowData.id, params).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                //console.log('response for request(\'getOfferPDFDocumentById\')', response);
                if (response.byteLength > 0) {
                    downloadFile(response, 'Angebot ' + rowData.angebot + '.pdf');
                } else {
                    toast.error('Die Datei ist nicht vorhanden');
                }
            }
        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
        });
    }

    const downloadOfferAttachment = (rowData) => {
        //attachmentTableState.current.isLoading = true; // we must prevent data loading because put here true
        setIsSpinnerVisible(true);

        request('getOfferAttachmentById', rowData.id).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                console.log('response for request(\'getOfferAttachmentById\')', response);
                let fileObj = response.offerAttachments[0] || {};
                if (response.succ && fileObj && fileObj.content.length > 0 && fileObj.name.length > 0 && fileObj.mimeType.length > 0) {
                    downloadFile(fileObj.content, fileObj.name, fileObj.mimeType, true);
                } else {
                    toast.error('Die Datei ist nicht vorhanden');
                }
            }
        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
            //attachmentTableState.current.isLoading = false; // set to default
        });
    }

    const deleteOfferAttachment = (rowData) => {
        if (!window.confirm("Wirklich löschen?")) {
            return;
        }

        //attachmentTableState.current.isLoading = true; // we must prevent data loading because put here true
        setIsSpinnerVisible(true);

        request('deleteOfferAttachment', rowData.id).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                console.log('response for request(\'deleteOfferAttachment\')', response);
                if (response.succ) {
                    attachmentTableState.current.data = attachmentTableState.current.data.filter(row => row.id != rowData.id);
                    toast.success('Die Datei wurde erfolgreich gelöscht');
                } else {
                    toast.error('Die Datei wurde nicht gelöscht');
                }
            }
        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
            //attachmentTableState.current.isLoading = false; // set to default
        });
    }

    const TransmitConfigurationToD365 = (rowData) => {
        console.log('TransmitConfigurationToD365(): rowData', rowData);

        if (rowData.lvl !== 3) {
            console.log('Something wrong...');
            return;
        }

        setIsSpinnerVisible(true);

        request('TransmitConfigurationToD365', rowData.id).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                rowData.D365Status = rowData.D365Status + 1; // refresh the count of clicks
                toast.success(response);
            }

        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
        });

        let newData = { [rowData.id]: 'danger' };
        setExportButtonColor({ ...exportButtonColor, ...newData });
    }

    const editAngebot = (rowData) => {
        console.log('entered editAngebot');

        if (rowData.lvl !== 3) {
            console.log('Something wrong...');
            return;
        }

        currentId.current = rowData.id;
        setFormVisible(true);

        return;
    }

    const isOfferFinal = (rowData) => {
        return allOfferStates.current.filter(state => state.description == rowData.status)[0]?.id == 4; // 4 is final state (abgeschlossen)
    }

    const loadAttachmentsData = (rowData) => {
        //console.log('showAttachmentPannel', rowData);

        // put current offer data to reference, it will be used in attachment form to upload files
        currentAttachmentPanel.current = rowData;

        /**
         * we have 3 states for isLoading:
         * true - during the loading or if we do not need to load right now (it means that the loading process will not be )
         * false - default state, load-process can be started after rerender the detail panel
         * uniqueID - only in case when we need to load remote data
         **/
        if (attachmentTableState.current.isLoading === false) {
            attachmentTableState.current.isLoading = rowData.id;
        }

        if (attachmentTableState.current.isLoading === rowData.id) {
            attachmentTableState.current.isLoading = true;
            attachmentTableState.current.data = [];
            setIsSpinnerVisible(true);

            request('getOfferAttachments', 0, { offerNumber: rowData.angebot, houseConfigModelId: rowData.houseConfigModelId, withoutContent: true }).then(response => {
                if (error.length === 0 && typeof response !== 'undefined') {
                    console.log('response for request(\'getOfferAttachments\')', response);
                    let tableAttachmentData = [];

                    if (response.offerAttachments.length > 0) {
                        response.offerAttachments.forEach(file => {
                            // save customer clients by customerId to global array
                            tableAttachmentData.push({
                                id: file.id,
                                name: file.name,
                                createdAt: file.createdAt ? formatDate(file.createdAt): '',
                                createdBy: file.createdBy,
                                type: file.mimeType,
                                notice: file.notice
                            });
                        });
                    }

                    //console.log('tableAttachmentData', tableAttachmentData);
                    attachmentTableState.current.data = tableAttachmentData;
                }
            }).finally(() => {
                setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
                attachmentTableState.current.isLoading = false;
            });
        }
    }

    const showAttachmentPannel = (rowData) => {
        //console.log('showAttachmentPannel', rowData);

        if (currentAttachmentPanel.current.id !== rowData.id || attachmentTableState.current.isLoading === rowData.id) {
            loadAttachmentsData(rowData);
        }

        return (
            <Grid container spacing={2}>
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={11} >
                    <MaterialTable
                        data={attachmentTableState.current.data}
                        columns={[
                            { title: 'ID', field: 'id', hidden: true },
                            { title: 'Dateiname', field: 'name' },
                            { title: 'Datum', field: 'createdAt', type: 'date', dateSetting: { locale: "de-DE" } },
                            { title: 'Erstellt von', field: 'createdBy' },
                            { title: 'Dateityp', field: 'type', hidden: true },
                            { title: 'Bemerkung', field: 'notice' },
                        ]}
                        actions={[
                            (rowData) => ({
                                icon: () => <GetAppIcon color="primary" />,
                                tooltip: 'Datei herunterladen',
                                onClick: (event, rowData) => {
                                    downloadOfferAttachment(rowData);
                                }
                            }),
                            (rowData) => ({
                                icon: () => <DeleteIcon color="primary" />,
                                tooltip: 'Datei löschen',
                                onClick: (event, rowData) => {
                                    deleteOfferAttachment(rowData);
                                }
                            }),
                            {
                                position: "toolbar",
                                icon: () => <QueueIcon color="primary" />,
                                tooltip: 'Hochladen',
                                onClick: (e, rowData) => {
                                    //attachmentTableState.current.isLoading = true; // we must prevent data loading because put here true
                                    setAttachmentsDialogVisible(true);
                                }
                            },
                        ]}
                        options={{
                            search: false,
                            showTitle: true,
                            toolbar: true,
                            paging: false,
                            headerShown: false,
                            headerStyle: {
                                position: 'sticky',
                                fontSize: '1.2em',
                                padding: '10px',
                                backgroundColor: '#f8c7a4',
                            },
                            rowStyle: (rowData) => ({
                                whiteSpace: 'pre-wrap',
                                backgroundColor: '#fffff0',
                            }),
                        }}
                        icons={MaterialTableIcons}
                        title="Dateien"
                        localization={{
                            body: {
                                emptyDataSourceMessage: isSpinnerVisible ?
                                    'Daten werden geladen...' :
                                    'Keine Daten vorhanden!'
                            },
                            header: {
                                actions: 'Aktionen'
                            },
                        }}
                    />
                </Grid>
            </Grid>
        )
    }

    // IMPORTANT!!!
    // If we put columns from state it SLOWS and BREAKS the browser at material-table above v.1.67.0
    // BUT it needs to keep filter and search and pagging stable and no refreshig... So we must use only v.1.67.0 (!!!)
    // Offizial Issue https://github.com/mbrn/material-table/issues/2451
    const [columnObject] = useState([
        { title: 'id', field: 'id', hidden: true },
        { title: 'key', field: 'key', hidden: true },
        { title: 'lvl', field: 'lvl', hidden: true },
        { title: 'parentId', field: 'parentId', hidden: true },
        { title: 'Kunde', field: 'name' },
        { title: 'KundeEmail', field: 'Emailname', hidden: true },
        { title: 'Handelsvertreter', field: 'handelsvertreter', width: '15%' },
        { title: 'Projektnr', field: 'projektNummer' },
        { title: 'hasProjektNummer', field: 'hasProjektNummer', hidden: true },
        { title: 'Titel Hausvorschlag', field: 'titelHausvorschlag', width: '15%' },
        { title: 'Datum', field: 'datum', type: 'date', filtering: false, dateSetting: { locale: "de-DE" }, style: { whitespace: 'nowrap' } },
        {
            title: 'Zeit', field: 'zeit', type: 'time', filtering: false, sorting: false, dateSetting: { locale: "de-DE" }
            , style: { whitespace: 'nowrap', wordbreak: 'keep-all'}
            , headerStyle: { color: '#f8c7a4',}
        },
        //{
        //    title: "Time", field: 'zeit', type: 'time', render: (datValue) => {
        //        return <div> {() => { if (datValue.zeit !== '') timeFullFormt(new Date(datValue.zeit)) }}</div>
        //    }
        //},
        //{
        //    title: "Time", field: 'zeit', width: "10%", cellStyle: { borderRight: "1px solid rgba(0, 0, 0, 0.12)" }, render: (datValue) => {
        //        //return <Typography fontSize="12px !important" > {datValue}</Typography>
        //        return <div fontSize="12px !important" > {datValue.zeit}</div>
        //    }
        //},
        { title: 'Angebot', field: 'angebot' },
        { title: 'Modellnr', field: 'houseConfigModelId' },
        { title: 'Rev', field: 'revision', defaultSort: 'desc' },
        { title: 'Status', field: 'status' },
        { title: 'Preis', field: 'preis' },
        { title: 'Bemerkung', field: 'bemerkungsfeld' },
    ]);


    const exportButtonIconColor = (rowData) => {

        if (Object.keys(exportButtonColor).length !== 0 && exportButtonColor.hasOwnProperty(rowData.id)) {
            if (rowData.status === "Storno" || rowData.status === "Abgelehnt" || rowData.status === "Entwurf") return 'disabled';

            else if (rowData.D365Status > 0) return 'danger';
            else return exportButtonColor[rowData.id];
        }

        else
            if (rowData.status === "Storno" || rowData.status === "Abgelehnt" || rowData.status === "Entwurf") return 'disabled';
            else if (rowData.D365Status > 0) return 'danger';
            else return 'primary';
    }
    return (
        <>
            {!isUserHasPermissionToThisPage ? '' : <Paper className={styles.paper} elevation={3}>

                {tableData.length > 0 && formVisible && <AngebotForm isOpen={formVisible}
                    close={() => {
                        setFormVisible(false);

                    }}
                    afterSave={() => {
                        loadTableData();
                    }}
                    currentId={currentId.current}
                    customerClients={clientsByCustomer.current}
                    allSupplyVouchers={allSupplyVouchers.current}
                    allSaleForceStatuses={allSaleForceStatuses.current}
                    allOfferStates={allOfferStates.current}
                    allSonderProducts={allSonderProducts.current}
                />}

                {tableData.length > 0 && attachmentsDialogVisible && <AngebotAttachments isOpen={attachmentsDialogVisible}
                    close={() => {
                        setAttachmentsDialogVisible(false);
                    }}
                    afterSave={() => {
                        // to rerender the data after upload
                        attachmentTableState.current.isLoading = currentAttachmentPanel.current.id;
                    }}
                    currentOfferData={currentAttachmentPanel.current}
                />}

                <SpinnerPopover isVisible={isSpinnerVisible} />

                <TableContainer>
                    <ThemeProvider theme={theme}>
                        <MaterialTable
                            columns={columnObject}

                            data={tableData}

                            tableRef={myTableRef}

                            parentChildData={(row, rows) => rows.find(a => a.key === row.parentId)}

                            detailPanel={[
                                rowData => ({
                                    hidden: rowData.angebot && rowData.houseConfigModelId ? false : true,
                                    disabled: rowData.angebot && rowData.houseConfigModelId && currentUserState.can(ANGEBOT_ATTACHMENTS_PANEL) ? false : true,
                                    icon: rowData.angebot && rowData.houseConfigModelId ? () => <AttachFileIcon /> : () => null,
                                    render: (rowData) => {
                                        return showAttachmentPannel(rowData);
                                    }
                                })
                            ]}

                            actions={[
                                (rowData) => ({
                                    hidden: rowData.lvl === 3 ? false : true,
                                    disabled: !currentUserState.can(ANGEBOT_FORM_OPEN) ? true : false,
                                    icon: () => <EditIcon color={!currentUserState.can(ANGEBOT_FORM_OPEN) ? "disabled" : "primary"} />,
                                    tooltip: rowData.lvl === 3 ? 'Angebot bearbeiten' : false,
                                    onClick: (event, rowData) => {
                                        editAngebot(rowData);
                                    }
                                }),
                                (rowData) => ({
                                    disabled: !currentUserState.can(ANGEBOT_FORM_EMAIL) ? true : false,
                                    hidden: rowData.lvl !== 1 ? true : false,
                                    icon: () => <MailOutlineIcon color={!currentUserState.can(ANGEBOT_FORM_EMAIL) ? "disabled" : "primary"} />,
                                    tooltip: rowData.lvl !== 1 ? '' : 'Eine E-mail erstellen',
                                    onClick: (event, rowData) => {
                                        window.location.href = "mailto:" + (rowData.Emailname ? rowData.Emailname : '');
                                    }
                                }),
                                (rowData) => ({
                                    disabled: !currentUserState.can(ANGEBOT_FORM_DOWNLOAD) ? true : false,
                                    hidden: rowData.lvl !== 3 || rowData.revision == 1 ? true : false,
                                    icon: () => <GetAppIcon color={!currentUserState.can(ANGEBOT_FORM_DOWNLOAD) ? "disabled" : "primary"} />,
                                    tooltip: rowData.lvl !== 3 || rowData.revision == 1 ? '' : 'Angebot herunterladen',
                                    onClick: (event, rowData) => {
                                        downloadAngebot(rowData);
                                    }
                                }),
                                (rowData) => ({
                                    hidden: rowData.lvl === 3 && currentUserState.can(ANGEBOT_EXPORT_TO_INCREASE_BUTTON) && rowData.hasProjektNummer ? false : true,
                                    disabled: rowData.status === "Storno" || rowData.status === "Abgelehnt" || rowData.status === "Entwurf",
                                    icon: () => <ExitToAppIcon color={exportButtonIconColor(rowData)} />,
                                    tooltip: rowData.lvl !== 3 ? '' : "Das Angebot wurde " + rowData.D365Status + " Mal exportiert",
                                    onClick: (event, rowData) => {
                                        TransmitConfigurationToD365(rowData);
                                    }
                                }),
                                {
                                    // this action needs to make a button for expand less tree view
                                    position: "toolbar",
                                    icon: () => <UnfoldMoreIcon color="primary" />,
                                    tooltip: 'Aufklappen',
                                    onClick: (e, rowData) => {
                                        //console.log('myTableRef', myTableRef);
                                        let newTableData = tableData.map(row => {
                                            row.tableData.isTreeExpanded = true;
                                            return row;
                                        });
                                        setTableData(newTableData);
                                    }
                                },
                                {
                                    // this action needs to make a button for expand more tree view
                                    position: "toolbar",
                                    icon: () => <UnfoldLessIcon color="primary" />,
                                    tooltip: 'Zuklappen',
                                    onClick: (e, rowData) => {
                                        //console.log('myTableRef', myTableRef);
                                        let newTableData = tableData.map(row => {
                                            row.tableData.isTreeExpanded = false;
                                            return row;
                                        });
                                        setTableData(newTableData);
                                    }
                                }
                            ]}

                            style={{
                                whiteSpace: "nowrap",
                                wordWrap: "break-word"

                            }}

                            options={{
                                toolbar: true,
                                search: true,
                                paging: true,
                                pageSize: 10,
                                filtering: true,
                                filterRowStyle: {
                                    position: "sticky",
                                    height: "40px",
                                    top: 10,
                                    background: "white",
                                    zIndex: 5,
                                    padding: "10px"
                                },
                                draggable: false,
                                detailPanelColumnAlignment: "left",
                                detailPanelType: "single", // (!) important, because the current implementation works only for one active panel same time, multiple variation can be implemented with extra ca 40 hours
                                defaultExpanded: false,
                                actionsColumnIndex: 0,
                                headerStyle: {
                                    position: 'sticky',
                                    fontSize: '1.2em',
                                    padding: '10px',
                                    backgroundColor: '#f8c7a4',
                                    //color: '#000',
                                },
                                maxBodyHeight: 'calc(100vh - 350px)',//216px)',
                                rowStyle: (rowData) => ({
                                    whiteSpace: 'pre-wrap',
                                    //padding: "5px",
                                    backgroundColor: isOfferFinal(rowData) ? '#a5ffd6' : (rowData.lvl === 1 ? '#eee' : (rowData.lvl === 2 ? '#f5f5dc' : undefined)),
                                }),
                            }}

                            title="Angebote"

                            icons={MaterialTableIcons}

                            localization={{
                                body: {
                                    emptyDataSourceMessage: isSpinnerVisible ?
                                        'Daten werden geladen...' :
                                        'Keine Daten vorhanden!'
                                },
                                header: {
                                    actions: 'Aktionen'
                                },
                            }}
                        />
                    </ThemeProvider>
                </TableContainer>
            </Paper>}
        </>
    );
}

export default Angebote;