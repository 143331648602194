import React, { Component } from 'react';
import { Switch, Route, Link, BrowserRouter, Redirect } from 'react-router-dom';
import { Layout } from './components/Layout';
import ProtectedRoute from './auth/protected-route';
import { Toaster } from 'react-hot-toast';

import { Home } from './pages/Home/Home';
import { Angebote } from './pages/Angebote/Angebote';
import { MitArbeiter } from './pages/Angebote/MitArbeiter';
import { Kunden } from './pages/Kunden/Kunden';
import { Vertragsunterlagen } from './pages/Vertragsunterlagen/Vertragsunterlagen';

import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';

import PersistantDrawer from './components/PersistantDrawer';

import { FeTest } from './pages/FE-Test/Fe-Test';

import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
        <Layout>
            <div><Toaster
                position="bottom-left"
                reverseOrder={false}
            /></div>
            <PersistantDrawer />
            <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/angebote' component={Angebote} />
                <Route path='/mitarbeiter' component={MitArbeiter} />
                <Route path='/kunden' component={Kunden} />
                <Route path='/vertragsunterlagen' component={Vertragsunterlagen} />

                <ProtectedRoute path='/counter' component={Counter} />
                <Route path='/fetch-data' component={FetchData} />

                <Redirect from="*" to="/" />
            </Switch>
      </Layout>
    );
  }
}