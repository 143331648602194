import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button, Typography } from "@material-ui/core";
import { User } from '../auth/User';

const LogoutButton = () => {
    const { logout, user } = useAuth0();
    // initialize custom User Component to check permisions
    const currentUserState = User();

    let name = '';
    let userRolesName = currentUserState.getUserRolesName();

    if (user.given_name?.length > 0 && user.family_name?.length > 0) {
        name = user.given_name + " " + user.family_name;
    } else if (user.name?.length > 0) {
        name = user.name;
    } else if (user.nickname?.length > 0) {
        name = user.nickname;
    } else if (user.email?.length > 0) {
        name = user.email;
    }

    if (userRolesName && userRolesName.length > 0) {
        userRolesName = ' (' + userRolesName + ')';
    }

    return (
        <>
            <Typography variant="button" style={{ "margin-right": "20px" }}><b>{name}</b><span style={{ "font-size": "10px" }}>{userRolesName}</span></Typography>
            <Button
                color="secondary"
                variant="contained"
                onClick={() =>
                logout({
                    returnTo: window.location.origin,
                })
                }
            >
                Log Out
            </Button>
        </>
    );
};

export default LogoutButton;