import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import jsonData from './data/data.json';
import jsonData4OfferPrice from './data/dataOfferPrice.json';

const ExternalApi = () => {
    const [message, setMessage] = useState('');
  const [priceinfo, setPriceinfo] = useState('');
  const [myAadUser, setMyAadUser] = useState();

  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const callPublicApi = async () => {
    try {
      var response;
      if (isAuthenticated) {
        const token = await getAccessTokenSilently();
        response = await fetch(`${getPath()}/api/getFruits`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            }
          }
        );      
      } else {
        response = await fetch(`app/getFruits`);
      }
      


      const responseData = await response.json();
      console.log(responseData);
      setMyAadUser(responseData);
      //setMessage(responseData);
    } catch (error) {
      setMessage(error.message);
    }
  };

  function getPath() {
    var a = "";
    switch (window.location.hostname) {
      case process.env["REACT_APP_Hostname"]:
        a = process.env["REACT_APP_Api_Path_Gen"];
        break;
      case process.env["REACT_APP_Hostname_Dev"]:
        a = process.env["REACT_APP_Api_Path_Gen_Dev"];
        break;
      case process.env["REACT_APP_Hostname_Testing"]:
        a = process.env["REACT_APP_Api_Path_Gen_Testing"];
        break;
      case process.env["REACT_APP_Hostname_localhost"]:
        a = process.env["REACT_APP_Api_Path_Gen_localhost"];
        break;
      case process.env["REACT_APP_Hostname_Haas_Prod"]:
        a = process.env["REACT_APP_Api_Path_Gen_Haas_Prod"];
        break;
      case process.env["REACT_APP_Hostname_Haas_Prod_Custom_Domain"]:
        a = process.env["REACT_APP_Api_Path_Gen_Haas_Prod"];
        break;
      case process.env["REACT_APP_Hostname_Haas_Dev"]:
        a = process.env["REACT_APP_Api_Path_Gen_Haas_Dev"];
        break;
      case process.env["REACT_APP_Hostname_Haas_Test"]:
        a = process.env["REACT_APP_Api_Path_Gen_Haas_Test"];
        break;
      default:
        a = process.env["REACT_APP_Api_Path_Gen"];
    }
   return a;
  }

  const callSecureApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      console.log(token);

      var a = getPath();

      console.log(a);

      const response = await fetch(
        a,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = await response.json();

      console.log(responseData);

      setMessage(responseData[0].summary);
    } catch (error) {
      setMessage(error.message);
    }
  };

  const getAdminApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      var a = `${getPath()}/api/getAdminApi`;
      console.log(token);
      const response = await fetch(
        a,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();

      console.log(responseData);
      setMessage(responseData);
    }
    catch (error) {
      setMessage(error.message);
    }
  }

  const getAdminOrSalesApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      var a = `${getPath()}/api/getAdminOrSalesApi`;
      console.log(token);
      const response = await fetch(
        a,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const responseData = await response.json();

      console.log(responseData);
      setMessage(responseData);
    }
    catch (error) {
      setMessage(error.message);
    }
  }

  const getEstimatePriceApi = async () => {
        try {
            //const token = await getAccessTokenSilently();
            //var a = `${getPath()}/api/getEstimatePrice`;
          var a = `app/getEstimatePrice`;
            const data = JSON.stringify(jsonData);
            var minPrice = 0;
            var maxPrice = 0;
            //var workingCost = 0;
            //console.log(token);
            //console.log(data);
          const response = await fetch(
            a,
            {
              method: "POST",
              headers: {
                //Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: data,
            }
          )
              .then((response) => {
                return response.json()
              })
              .then(response => {
                return JSON.parse(response)
              })
              .then(response => {
                minPrice = response.priceInfo.minPrice
                  maxPrice = response.priceInfo.maxPrice
                  //workingCost = response.priceInfo.workingCost
              });

            //setPriceinfo("Der Preis liegt zwischen " + minPrice + " € und " + maxPrice
            //    + " € " + "davon sind " + workingCost + " € Lohnkosten");
            setPriceinfo("Der Preis liegt zwischen " + minPrice + " € und " + maxPrice + " € ");

        }
        catch (error) {
            setPriceinfo(error.message);
        }
    }

    const getOfferPriceApi = async () => {
        try {
            const token = await getAccessTokenSilently();
            var a = `${getPath()}/api/getOfferPrice`;
            const dataOffer = JSON.stringify(jsonData4OfferPrice);
            var response = await fetch(
                a,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                        Accept: "application/json",
                    },
                    body: dataOffer,
                }
            );
            const responseData = await response.json();
            //setPriceinfo("Der genaue Preis ist " + responseData.priceInfo.offerPrice + " € "
            //    + "davon sind " + responseData.priceInfo.workingCost + " € Lohnkosten");

            setPriceinfo("Der genaue Preis ist " + responseData.priceInfo.offerPrice + " € ");

        }
        catch (error) {
            // If you get JSON-Parse error in GUI, please check your user group!!! 
            setPriceinfo(error.message);
        }
  }

    //const getOfferPDFDocumentById = async () => {
    //    try {
    //        console.log("Offer PDF button clicked!")
    //        const token = await getAccessTokenSilently();
    //        var a = `${getPath()}/api/getOfferPDFDocumentById2`;
    //        var url = new URL(a)
    //        var Id = 152
    //        var params = {
    //            Id
    //        }
    //        url.search = new URLSearchParams(params).toString();

    //        fetch(url,
    //            {
    //                method: "GET",
    //                headers: {
    //                    Authorization: `Bearer ${token}`,
    //                    "Content-Type": "application/json",
    //                    Accept: "application/json",
    //                }
    //            })
    //            .then(res => {
    //                return res.blob();
    //            }).then(blob => {
    //                const href = window.URL.createObjectURL(blob);
    //                const link = document.createElement('a');
    //                link.href = href;
    //                link.setAttribute('download', "offer.pdf"); //or any other extension
    //                document.body.appendChild(link);
    //                link.click();
    //                document.body.removeChild(link);
    //            }).catch(err => console.error(err));

    //    } catch (error) {
    //        setMessage(error.message);
    //    }
    //}
  return (
    <div className="container">
      <h1>External API</h1>
      <p>
        Use these buttons to call an external API. The protected API call has an
        access token in its authorization header. The API server will validate
        the access token using the Auth0 Audience value.
      </p>
      <div
        className="btn-group mt-5"
        role="group"
        aria-label="External API Requests Examples"
      >
        <button type="button" className="btn btn-primary" onClick={callPublicApi}>
          Get my Direct Reports
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={callSecureApi}
        >
          Registered Only
        </button>
        <button
          type="button"
          className="btn btn-primary"
          onClick={getAdminApi}
        >
          Admins Only
        </button>

        <button
          type="button"
          className="btn btn-primary"
          onClick={getAdminOrSalesApi}
        >
                  Admin & Sales-User
        </button>

        <button
          type="button"
           className="btn btn-primary"
           onClick={getEstimatePriceApi}
              >
                  GetEstimatePrice
        </button>

        <button
          type="button"
          className="btn btn-primary"
          onClick={getOfferPriceApi}
            >
                GetOfferPrice
        </button>
        <br />
        <br />
        <br />
        {/*<button*/}
        {/*  type="button"*/}
        {/*  className="btn btn-primary"*/}
        {/*  onClick={getOfferPDFDocument}>*/}
        {/*  GetOfferPDF*/}
        {/*</button>*/}
      </div>
      {message && (
        <div className="mt-5">
          <h6 className="muted">Result</h6>
          <div className="container-fluid">
            <div className="row">
              <code className="col-12 text-light bg-dark p-4">{message}</code>
            </div>
          </div>
        </div>
      )}
      <br/>
      <br/>
      <br/>
      {myAadUser && (
        <div className="mt-5">
          <h6 className="muted">Me:</h6>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">name: {myAadUser.displayName} </code>
              </div>        
          </div>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">company name: {myAadUser.companyName} </code>
              </div>        
          </div>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">email: {myAadUser.email} </code>
              </div>        
          </div>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">upn: {myAadUser.upn} </code>
              </div>        
          </div>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">Phone: {myAadUser.phone} </code>
              </div>        
          </div>
           <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">Mobile: {myAadUser.mobile} </code>
              </div>        
          </div>
          <div className="container-fluid">
              <div className="row">
              <code className="col-12 text-light bg-dark p-4">Address: {myAadUser.street}, {myAadUser.zipCode} {myAadUser.city}, {myAadUser.country}</code>
              </div>        
          </div>
          
        </div>
      )}
      <br />
      <br />
      <br />
      {myAadUser && (
        <div className="mt-5">
          <h6 className="muted">Direct Reports:</h6>
          <div className="container-fluid">
            {myAadUser.directReports.map(dr => (
              <div className="row">
                <code className="col-12 text-light bg-dark p-4">name: {dr.displayName}, company name: {dr.companyName}, email: {dr.email}, upn: {dr.upn}</code>
              </div>
              ))}
            
          </div>
        </div>
      )}

      {priceinfo && (
              <div className="mt-5">
                  <h6 className="muted">Price Information:</h6>
                  <div className="container-fluid">
                      <div className="row">
                          <code className="col-12 text-light bg-dark p-4">{priceinfo}</code>
                      </div>
                  </div>
              </div>
              )}
    </div>
  );
};

export default ExternalApi;