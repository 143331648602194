import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { Grid, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import toast from 'react-hot-toast';

import SpinnerPopover from '../../components/SpinnerPopover';
import { useMakeRequest } from '../../api/Requests';

const theme = createMuiTheme({
    overrides: {
        MuiDropzoneArea: {
            root: {
                width: '800px',
            },
        }
    }
});

const initialState = {
    notice: '',
    files: []
};

export default function AngebotAttachments({ ...props }) {
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [myValues, setMyValues] = useState(initialState);

    // initialize custom API useEffect to make requests
    const { request, error } = useMakeRequest();

    useEffect(() => {
        if (props.isOpen) {
            setMyValues(initialState);
        } else {
            console.log('Form is closed, skipping init...');
        }
    }, [props.isOpen]);

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        let fieldValue = { [name]: value };

        setMyValues({
            ...myValues,
            ...fieldValue
        });
    }

    const submit = () => {
        setIsSpinnerVisible(true);

        let dataToSave = new FormData();
        let files = myValues.files?.forEach(file =>
            dataToSave.append('files', file, file.name)
        );
        dataToSave.append('Notice', myValues.notice);
        dataToSave.append('Offernumber', props.currentOfferData.angebot);
        dataToSave.append('HouseConfigModelId', props.currentOfferData.houseConfigModelId);

        const afterSaveEvents = () => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
            props.afterSave();
            props.close();
        };

        request('offerAttachmentUpload', '', dataToSave).then(response => {
            if (error.length === 0 && typeof response !== 'undefined') {
                if (response.succ) {
                    toast.success('Die Dateien wurden erfolgreich hochgeladen!');
                } else {
                    toast.error(response.message);
                }
            }
        }).finally(() => {
            afterSaveEvents();
        });
    };

    //droppedFiles is an Array of fileObjects
    const dropzoneOnChange = (droppedFiles) => {
        let filesValue = { files: droppedFiles };
        setMyValues({
            ...myValues,
            ...filesValue,
        });
    }

    return (
        <>
        {
            props.isOpen === false ? '' : <Dialog
                open={props.isOpen}
                onClose={props.close}
                aria-labelledby="draggable-dialog-title"
                maxWidth='xl'
                fullWidth={false}
                disableEscapeKeyDown
            >
                <DialogTitle id="draggable-dialog-title">
                    Angebotsdateien
                </DialogTitle>

                <DialogContent style={{ marginTop: '90px' }}>
                    <SpinnerPopover isVisible={isSpinnerVisible} />

                    <Grid container>
                        <Grid item xs={12}>
                            <MuiThemeProvider theme={theme}>
                                <DropzoneArea
                                    onChange={dropzoneOnChange}
                                    //acceptedFiles={["image/jpeg", "image/png"]}
                                    filesLimit={10}
                                    useChipsForPreview={true}
                                    showAlerts={false}
                                    maxFileSize={5000000}
                                    dropzoneText={"Fügen Sie Deteien hinzu"}
                                    previewText={"Dateien"}
                                />
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginTop: '30px' }}>
                        <Grid item xs={12}>
                            <TextField
                                label='Bemerkung'
                                name='notice'
                                value={myValues.notice}
                                style={{ width: '100%' }}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={submit} disabled={myValues.files.length < 1} color="primary">
                        Speichern
                    </Button>
                    <Button onClick={props.close} color="primary">
                        Abbrechen
                    </Button>

                </DialogActions>
            </Dialog>
        }
        </>
    );
}