import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import MaterialTableIcons from '../../components/MaterialTableIcons';
import { makeStyles, createStyles } from '@material-ui/core';

import GetAppIcon from '@material-ui/icons/GetApp';

import { downloadFile } from '../../helper';

import SpinnerPopover from '../../components/SpinnerPopover';
import { useMakeRequest } from '../../api/Requests';

import toast from 'react-hot-toast';
import { User, VERTRAGSUNTERLAGEN } from '../../auth/User';

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            margin: '25px',
            padding: '25px'
        },
    }));

export function Vertragsunterlagen() {
    const styles = useStyles();
    const [tableData, setTableData] = useState([]);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);

    // initialize custom API useEffect to make requests
    const { request, error } = useMakeRequest();

    // initialize custom User Component to check permisions
    const currentUserState = User();

    useEffect(() => {
        // check user permissions to this page
        if (!currentUserState.isLoading && !currentUserState.can(VERTRAGSUNTERLAGEN)) {
            toast.error('403 Zugriff verweigert');
        } else if(currentUserState.can(VERTRAGSUNTERLAGEN)) {
            loadTableData();
            console.log('is initialized');
        }
    }, [currentUserState.isLoading]);

    const loadTableData = () => {
        setIsSpinnerVisible(true);

        request('getAllTemplates',0, null).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                if (response.length === 0) {
                    response = [];
                } else {
                    response = JSON.parse(response);
                }
                console.log('response for request(\'getAllTemplates\')', response);

                let preparedData = [];

                if (response.length > 0) {
                    response.forEach(res => {
                        preparedData.push({
                            templateName: res.templateName,
                            templateFormat: res.templateFormat,
                            templateOrder: res.templateOrder
                        });

                    });
                }

                //console.log('preparedData', preparedData);

                setTableData(preparedData);
            }
        }).finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
        });
    }

    const downloadTemplate = (rowData) => {
        //console.log(rowData);
        setIsSpinnerVisible(true);
        let extension = rowData.templateFormat.toLowerCase();

        let params = {
            tempName: rowData.templateName,
            extension: extension.substring(1) // delete "." from start
        };

        request('getTemplateByName', '', params).then(response => {

            if (error.length === 0 && typeof response !== 'undefined') {
                //console.log('response for request(\'getTemplateByName\')', response);
                downloadFile(response, rowData.templateName + extension);
            }
        })
        .finally(() => {
            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
        });
    }

    // IMPORTANT!!!
    // If we put columns from state it SLOWS and BREAKS the browser at material-table above v.1.67.0
    // BUT it needs to keep filter and search and pagging stable and no refreshig... So we must use only v.1.67.0 (!!!)
    // Offizial Issue https://github.com/mbrn/material-table/issues/2451
    const [columnObject] = useState([
        { title: 'Datei', field: 'templateName', render: rowData => { return rowData.templateName + rowData.templateFormat } },
        { title: 'Dateinameerweiterung', field: 'templateFormat', hidden: true },
        { title: 'Position', field: 'templateOrder', defaultSort: 'asc', hidden: true },
    ]);

    return (
        <>
            {!currentUserState.can(VERTRAGSUNTERLAGEN) ? '' : <Paper className={styles.paper} elevation={3}>

                <SpinnerPopover isVisible={isSpinnerVisible} />

                <TableContainer>
                    <MaterialTable
                        columns={columnObject}

                        data={tableData}

                        actions={[
                            (rowData) => ({
                                icon: () => <GetAppIcon color="primary" />,
                                tooltip: 'Template herunterladen',
                                onClick: (event, rowData) => {
                                    downloadTemplate(rowData);
                                }
                            }),
                        ]}

                        style={{
                            whiteSpace: "nowrap",
                            wordWrap: "break-word"

                        }}

                        options={{
                            toolbar: true,
                            search: true,
                            paging: true,
                            pageSize: 10,
                            filtering: true,
                            filterRowStyle: {
                                position: "sticky",
                                top: 56,
                                background: "white",
                                zIndex: 5
                            },
                            detailPanelColumnAlignment: "left",
                            defaultExpanded: false,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                position: 'sticky',
                                fontSize: '1.2em',
                                padding: '15px',
                                backgroundColor: '#f8c7a4',
                            },
                            maxBodyHeight: 'calc(100vh - 216px)',
                            rowStyle: (rowData) => ({
                                whiteSpace: 'pre-wrap',
                                backgroundColor: rowData.parentId == 0 ? '#eee' : undefined,
                            }),
                        }}

                        title="Vertragsunterlagen"

                        icons={MaterialTableIcons}

                        localization={{
                            body: {
                                emptyDataSourceMessage: isSpinnerVisible ?
                                    'Daten werden geladen...' :
                                    'Keine Daten vorhanden!'
                            },
                            header: {
                                actions: 'Aktionen'
                            },
                        }}
                    />
                </TableContainer>
            </Paper>}
        </>
    );
}