import React, { useState, useEffect, useRef } from 'react';
import { TableContainer, Paper } from '@material-ui/core';
import MaterialTable from 'material-table';
import MaterialTableIcons from '../../components/MaterialTableIcons';
import { makeStyles, createStyles } from '@material-ui/core';

import EditIcon from "@material-ui/icons/Edit";
import GetAppIcon from '@material-ui/icons/GetApp';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';

import { formatter, downloadFile } from '../../helper';
import toast from 'react-hot-toast';

/*import AngebotForm from './AngebotForm';*/
import SpinnerPopover from '../../components/SpinnerPopover';

import { useMakeRequest } from '../../api/Requests';

const useStyles = makeStyles((theme) =>
    createStyles({
        paper: {
            margin: '25px',
            padding: '25px'
        },
    }));

export function FeTest() {
    const styles = useStyles();
    const [tableData, setTableData] = useState([]);
    const [isSpinnerVisible, setIsSpinnerVisible] = useState(false);
    const [initialized, setInitialized] = useState(false);
    const [providersReady, setProvidersReady] = useState(false);
    const [formVisible, setFormVisible] = useState(false);
    const [offerById, setOfferById] = useState(0);

    // initialize custom API useEffect to make requests
    const { request, error } = useMakeRequest();

    // is a reference which contains all clients by customerId
    const clientsByCustomer = useRef([]);
    // is a reference which contains all supplyVouchers
    const allSupplyVouchers = useRef([]);
    // is a reference which contains all SaleForce Statuses
    const allSaleForceStatuses = useRef([]);
    // is a reference which contains all offer states
    const allOfferStates = useRef([]);
    // is a reference which contains current offer Id for edit form
    const currentId = useRef(0);

    useEffect(() => {
        //loadTableData();
        GetWithoutId();
        GetById();
        PostTest();
    }, []);

    const GetWithoutId = () => {
        request('GetWithoutId').then(response => {
            console.log('Simple get without Id', response);
        });
    }

    const GetById = () => {
        request('GetById',"IdIsAString").then(response => {
            console.log('get with Id', response);
        });
    }

    const PostTest = () => {
        request('PostTest',1, {Id:10,Name:"name"}).then(response => {
            console.log('Post with object Params', response);
        });
    }


    //useEffect(() => {
    //    //debugger
    //    if (initialized) {
    //        console.log("useEffect 3: initialized are true");

    //        // if we have ID from URL, then open Edit Modal
    //        if (offerById > 0) {
    //            // find necessary entity or set empty object in right type
    //            let offerRow = tableData.find((sp) => (sp.id === offerById)) || {};
    //            // open window to edit if row was found
    //            if (offerRow && Object.keys(offerRow).length !== 0) {
    //                editAngebot(offerRow);
    //            }
    //            // set to default, it prevents this action fo future till refresh whole site
    //            setOfferById(0);
    //        }
    //    }
    //}, [initialized])

    //const loadTableData = () => {
    //    setIsSpinnerVisible(true);

    //    request('getAllCustomer').then(response => {

    //        if (error.length === 0 && typeof response !== 'undefined') {
    //            //console.log('response for request(\'getAllCustomers\')', response);

    //            let preparedData = [];

    //            // save additional data to references
    //            allSupplyVouchers.current = response.supplyVouchers;
    //            allSaleForceStatuses.current = response.commonOfferStages;
    //            allOfferStates.current = response.offerState;

    //            if (response.customers.length > 0) {
    //                response.customers.forEach(customer => {
    //                    // save customer clients by customerId to global array
    //                    clientsByCustomer.current.push({
    //                        customerId: customer.customerId,
    //                        clients: customer.customerClients
    //                    });

    //                    // gets names of clients
    //                    let getClientName = (clientModel) => {
    //                        return (clientModel.namePrefix && clientModel.namePrefix.length > 0 ? clientModel.namePrefix + ' ' : '') + (clientModel.firstName && clientModel.firstName.length > 0 ? clientModel.firstName + ' ' : '') + (clientModel.lastName && clientModel.lastName.length > 0 ? clientModel.lastName : '');
    //                    };
    //                    let customerClients = '';
    //                    let primaryClients = customer.customerClients.filter(clients => clients.primary == true);

    //                    if (primaryClients.length > 0) { // if we have found primary, then put only all primary
    //                        customerClients = primaryClients.map(clients => {
    //                            return getClientName(clients);
    //                        }).join(', ');
    //                    } else if (customer.customerClients.length > 0) { // else puts only first name from all clients
    //                        customerClients = getClientName(customer.customerClients[0]);
    //                    }

    //                    // puts current customer
    //                    preparedData.push({
    //                        id: customer.customerId,
    //                        parentId: 0,
    //                        projektNummer: '',
    //                        name: customerClients,
    //                        handelsvertreter: customer.assignedTo,
    //                        titelHausvorschlag: '',
    //                        status: '',
    //                        datum: '',
    //                        angebot: '',
    //                        houseConfigModelId: '',
    //                        revision: '',
    //                        preis: '',
    //                        bemerkungsfeld: ''
    //                    });

    //                    // puts offers to current customer
    //                    if (customer && customer.offers && Object.keys(customer.offers).length !== 0) {
    //                        customer.offers.forEach(offers => {
    //                            preparedData.push({
    //                                id: offers.id,
    //                                parentId: offers.customerId,
    //                                projektNummer: '',
    //                                name: '',
    //                                handelsvertreter: '',
    //                                titelHausvorschlag: offers.houseTitle,
    //                                status: offers.offerState,
    //                                datum: offers.offerDate,
    //                                angebot: offers.offerNumber,
    //                                houseConfigModelId: offers.houseConfigModelId,
    //                                revision: offers.offerRevision,
    //                                preis: formatter.format(offers.price),
    //                                bemerkungsfeld: offers.description
    //                            });
    //                        });
    //                    }
    //                });
    //            }

    //            //console.log('preparedData', preparedData);

    //            setTableData(preparedData);
    //            setInitialized(true);
    //            console.log('is initialized: ' + initialized);

    //        }
    //    }).finally(() => {
    //        setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
    //    });
    //}

    //const downloadAngebot = (rowData) => {
    //    if (rowData.parentId == 0) {
    //        console.log('Something wrong...');
    //        return;
    //    }

    //    setIsSpinnerVisible(true);
    //    let params = {
    //        revision: rowData.revision
    //    };

    //    request('getOfferPDFDocumentById', rowData.id, params).then(response => {

    //        if (error.length === 0 && typeof response !== 'undefined') {
    //            //console.log('response for request(\'getOfferPDFDocumentById\')', response);
    //            if (response.byteLength > 0) {
    //                downloadFile(response, 'Angebot ' + rowData.angebot + '.pdf');
    //            } else {
    //                toast.error('Die Datei ist nicht vorhanden');
    //            }
    //        }
    //    })
    //        .finally(() => {
    //            setIsSpinnerVisible(false); // we must clean-up our loading do not matter on success or failure
    //        });
    //}

    //const editAngebot = (rowData) => {
    //    console.log('entered editAngebot');

    //    if (rowData.parentId == 0) {
    //        console.log('Something wrong...');
    //        return;
    //    }

    //    currentId.current = rowData.id;
    //    console.log(`Target is Offer, offerId = ${rowData.id}, customerId = ${rowData.parentId}`, 'clientsByCustomer = ', clientsByCustomer);
    //    setFormVisible(true);

    //    return;
    //}

    //const isOfferFinal = (rowData) => {
    //    return allOfferStates.current.filter(state => state.description == rowData.status)[0]?.id == 4; // 4 is final state (abgeschlossen)
    //}

    //// IMPORTANT!!!
    //// If we put columns from state it SLOWS and BREAKS the browser at material-table above v.1.67.0
    //// BUT it needs to keep filter and search and pagging stable and no refreshig... So we must use only v.1.67.0 (!!!)
    //// Offizial Issue https://github.com/mbrn/material-table/issues/2451
    //const [columnObject] = useState([
    //    { title: 'id', field: 'id', hidden: true },
    //    { title: 'parentId', field: 'parentId', hidden: true },
    //    { title: 'Projektnr', field: 'projektNummer' },
    //    { title: 'Kunde', field: 'name' },
    //    { title: 'Handelsvertreter', field: 'handelsvertreter' },
    //    { title: 'Titel Hausvorschlag', field: 'titelHausvorschlag' },
    //    { title: 'Status', field: 'status' },
    //    { title: 'Datum', field: 'datum', type: 'date', filtering: false },
    //    { title: 'Angebot', field: 'angebot' },
    //    { title: 'Modellnr', field: 'houseConfigModelId' },
    //    { title: 'Rev', field: 'revision', defaultSort: 'desc' },
    //    { title: 'Preis', field: 'preis' },
    //    { title: 'Bemerkung', field: 'bemerkungsfeld' },
    //]);

    return (
        <>
            <Paper className={styles.paper} elevation={3}>

                {/*{tableData.length > 0 && formVisible && <AngebotForm isOpen={formVisible}*/}
                {/*    close={() => {*/}
                {/*        setFormVisible(false);*/}

                {/*    }}*/}
                {/*    afterSave={() => {*/}
                {/*        loadTableData();*/}
                {/*    }}*/}
                {/*    currentId={currentId.current}*/}
                {/*    customerClients={clientsByCustomer.current}*/}
                {/*    allSupplyVouchers={allSupplyVouchers.current}*/}
                {/*    allSaleForceStatuses={allSaleForceStatuses.current}*/}
                {/*    allOfferStates={allOfferStates.current}*/}
                {/*/>}*/}

                {/*<SpinnerPopover isVisible={isSpinnerVisible} />*/}

                {/*<TableContainer>*/}
                {/*    <MaterialTable*/}
                {/*        columns={columnObject}*/}

                {/*        data={tableData}*/}

                {/*        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}*/}

                {/*        actions={[*/}
                {/*            (rowData) => ({*/}
                {/*                icon: () => <EditIcon color="primary" />,*/}
                {/*                tooltip: rowData.parentId !== 0 ? 'Angebot bearbeiten' : 'Projekt bearbeiten',*/}
                {/*                onClick: (event, rowData) => {*/}
                {/*                    rowData.parentId !== 0 ? editAngebot(rowData) : alert('Bearbeiten wurde gedrückt');*/}
                {/*                }*/}
                {/*            }),*/}
                {/*            (rowData) => ({*/}
                {/*                hidden: rowData.parentId === 0 ? true : false,*/}
                {/*                icon: () => <MailOutlineIcon color="primary" />,*/}
                {/*                tooltip: rowData.parentId === 0 ? '' : 'Eine E-mail erstellen',*/}
                {/*                onClick: (event, rowData) => {*/}
                {/*                    alert('E-mail erstellen wurde gedrückt');*/}
                {/*                }*/}
                {/*            }),*/}
                {/*            (rowData) => ({*/}
                {/*                hidden: rowData.parentId === 0 || rowData.revision == 1 ? true : false,*/}
                {/*                icon: () => <GetAppIcon color="primary" />,*/}
                {/*                tooltip: rowData.parentId === 0 || rowData.revision == 1 ? '' : 'Angebot herunterladen',*/}
                {/*                onClick: (event, rowData) => {*/}
                {/*                    downloadAngebot(rowData);*/}
                {/*                }*/}
                {/*            }),*/}
                {/*            (rowData) => ({*/}
                {/*                hidden: rowData.parentId !== 0 ? true : false,*/}
                {/*                icon: () => <PermContactCalendarIcon color="primary" />,*/}
                {/*                tooltip: rowData.parentId === 0 ? 'VCF-Kontakt erstellen' : '',*/}
                {/*                onClick: (event, rowData) => {*/}
                {/*                    alert('VCF-Kontakt wurde gedrückt');*/}
                {/*                }*/}
                {/*            }),*/}
                {/*        ]}*/}

                {/*        style={{*/}
                {/*            whiteSpace: "nowrap",*/}
                {/*            wordWrap: "break-word"*/}

                {/*        }}*/}

                {/*        options={{*/}
                {/*            toolbar: true,*/}
                {/*            search: true,*/}
                {/*            paging: true,*/}
                {/*            pageSize: 10,*/}
                {/*            filtering: true,*/}
                {/*            filterRowStyle: {*/}
                {/*                position: "sticky",*/}
                {/*                top: 56,*/}
                {/*                background: "white",*/}
                {/*                zIndex: 5*/}
                {/*            },*/}
                {/*            detailPanelColumnAlignment: "left",*/}
                {/*            defaultExpanded: false,*/}
                {/*            actionsColumnIndex: -1,*/}
                {/*            headerStyle: {*/}
                {/*                position: 'sticky',*/}
                {/*                fontSize: '1.2em',*/}
                {/*                padding: '15px',*/}
                {/*                backgroundColor: '#f8c7a4',*/}
                {/*            },*/}
                {/*            maxBodyHeight: 'calc(100vh - 216px)',*/}
                {/*            rowStyle: (rowData) => ({*/}
                {/*                whiteSpace: 'pre-wrap',*/}
                {/*                backgroundColor: isOfferFinal(rowData) ? '#a5ffd6' : (rowData.parentId == 0 ? '#eee' : undefined),*/}
                {/*            }),*/}
                {/*        }}*/}

                {/*        title="Angebote"*/}

                {/*        icons={MaterialTableIcons}*/}

                {/*        localization={{*/}
                {/*            body: {*/}
                {/*                emptyDataSourceMessage: isSpinnerVisible ?*/}
                {/*                    'Daten werden geladen...' :*/}
                {/*                    'Keine Daten vorhanden!'*/}
                {/*            },*/}
                {/*            header: {*/}
                {/*                actions: 'Aktionen'*/}
                {/*            },*/}
                {/*        }}*/}
                {/*    />*/}
                {/*</TableContainer>*/}
            </Paper>
        </>
    );
}