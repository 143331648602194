import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import ExternalApi from './external-api';

import { User, COC_FETCHDATA } from '../auth/User';

export function FetchData() {
    const currentUserState = User();

    useEffect(() => {
        // check user permissions to this page
        if (!currentUserState.isLoading && !currentUserState.can(COC_FETCHDATA)) {
            toast.error('403 Zugriff verweigert');
        }
    }, [currentUserState.isLoading]);

    return (
        <>
            {!currentUserState.can(COC_FETCHDATA) ? '' : <ExternalApi />}
        </>
    );
}