import { useState, useEffect } from 'react';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import toast from 'react-hot-toast';

function getPath() {
    var a = "";
    switch (window.location.hostname) {
        case process.env["REACT_APP_Hostname"]:
            a = process.env["REACT_APP_Api_Path_Gen"];
            break;
        case process.env["REACT_APP_Hostname_Dev"]:
            a = process.env["REACT_APP_Api_Path_Gen_Dev"];
            break;
        case process.env["REACT_APP_Hostname_Testing"]:
            a = process.env["REACT_APP_Api_Path_Gen_Testing"];
            break;
        case process.env["REACT_APP_Hostname_localhost"]:
            a = process.env["REACT_APP_Api_Path_Gen_localhost"];
          break;
        case process.env["REACT_APP_Hostname_Haas_Prod"]:
          a = process.env["REACT_APP_Api_Path_Gen_Haas_Prod"];
            break;
        case process.env["REACT_APP_Hostname_Haas_Prod_Custom_Domain"]:
            a = process.env["REACT_APP_Api_Path_Gen_Haas_Prod"];
            break;
        case process.env["REACT_APP_Hostname_Haas_Dev"]:
          a = process.env["REACT_APP_Api_Path_Gen_Haas_Dev"];
          break;
        case process.env["REACT_APP_Hostname_Haas_Test"]:
          a = process.env["REACT_APP_Api_Path_Gen_Haas_Test"];
          break;
        default:
            a = process.env["REACT_APP_Api_Path_Gen"];
    }
    return a;
}

// API methods
// all functions must have param: token,
// and can contain next extra params: id, params
const getAllCustomer = (token, id, params) => {
    return axios.get(getPath() + '/api/getAllCustomer', { params: { ...params }, headers: { Authorization: `Bearer ${token}` } });
}

const getOfferByOfferId = (token, id, params) => {
    return axios.get(getPath() + '/api/getOfferByOfferId?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
}

const updateOfferByOfferId = (token, id, params) => {
    return axios.put(getPath() + '/api/updateOfferById?id=' + id, params, { headers: { Authorization: `Bearer ${token}` } });
}

const getAllTemplates = (token, id, params) => {
    if (params !== null) {
        return axios.get(getPath() + '/api/getAllTemplates?dataArea=' + params, { headers: { Authorization: `Bearer ${token}` } });
    } else {
        return axios.get(getPath() + '/api/getAllTemplates', { headers: { Authorization: `Bearer ${token}` } });
    }

}

const getOfferPDFDocumentById = (token, id, params) => {
    return axios.get(getPath() + '/api/getOfferPDFDocumentById?id=' + id + '&revision=' + params.revision, { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${token}`, 'Accept': 'application/pdf' } });
}

const getTemplateByName = (token, id, params) => {
    let searchParams = new URLSearchParams(params).toString();
    return axios.get(getPath() + '/api/getTemplateByName?' + searchParams, { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${token}` } });
}

const getFileByName = (token, id, params) => {
    let searchParams = new URLSearchParams(params).toString();
    let retValues = axios.get(getPath() + '/api/getFileByName?' + searchParams, { responseType: 'arraybuffer', headers: { Authorization: `Bearer ${token}` } })
    return retValues;
}

const TransmitConfigurationToD365 = (token, id, params) => {
  return axios.get(getPath() + '/api/TransmitConfigurationToD365?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
}

const offerAttachmentUpload = (token, id, params) => {
    return axios.post(getPath() + '/api/offerattachmentupload', params, { headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}` } });
}

const getOfferAttachments = (token, id, params) => {
    return axios.get(getPath() + '/api/getAllAttachments', { params: { ...params }, headers: { Authorization: `Bearer ${token}` } });
}

const getOfferAttachmentById = (token, id, params) => {
    return axios.get(getPath() + '/api/getattachment?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
}

const deleteOfferAttachment = (token, id, params) => {
    return axios.delete(getPath() + '/api/deleteattachment?id=' + id, { headers: { Authorization: `Bearer ${token}` } });
}


//Test

const GetWithoutId = (token, id, params) => {
  
    return axios.get(getPath() + '/WeatherForecast/GetAll', { headers: { Authorization: `Bearer ${token}` } });
}

const GetById = (token, id, params) => {
    return axios.get(getPath() + '/WeatherForecast/GetById/' + id, { headers: { Authorization: `Bearer ${token}` } });
}

const PostTest = (token, id, IsEmployer) => {
    return axios.post(getPath() + '/WeatherForecast/getMitArbeiterCustomers' + "/" + IsEmployer, { headers: { Authorization: `Bearer ${token}` } });
}

// IMPORTANT: after initialize new request method above, must be mapped functions and their names
const functions = {
    getAllCustomer: getAllCustomer,
    getOfferByOfferId: getOfferByOfferId,
    updateOfferByOfferId: updateOfferByOfferId,
    getAllTemplates: getAllTemplates,
    getOfferPDFDocumentById: getOfferPDFDocumentById,
    getTemplateByName: getTemplateByName,
    getFileByName: getFileByName,
    TransmitConfigurationToD365: TransmitConfigurationToD365,
    offerAttachmentUpload: offerAttachmentUpload,
    getOfferAttachments: getOfferAttachments,
    getOfferAttachmentById: getOfferAttachmentById,
    deleteOfferAttachment: deleteOfferAttachment,

    GetWithoutId: GetWithoutId,
    GetById: GetById,
    PostTest: PostTest,
};

export function useMakeRequest() {
    const [error, setError] = useState("");
    const { getAccessTokenSilently } = useAuth0();

    // return string with all errors
    const getValidationData = (data) => {
        let errors = [];

        if (data.title && data.title.length > 0)
            errors.push(data.title);

        if (data && data.length > 0 && (typeof data === 'string' || data instanceof String))
            errors.push(data);

        if (!data.isRequestValid && data.validationMessages && Object.keys(data.validationMessages).length !== 0) {
            errors = data.validationMessages.map(m => {
                return m.message;
            });
        }

        if (data && (typeof data === 'object' || data instanceof Object) && !data.title) {
            if (data.errors && Object.keys(data.errors).length !== 0) {
                errors.push(Object.values(data.errors).map(item => {
                    if (item && item.length > 0 && (typeof item === 'string' || item instanceof String)) {
                        return item;
                    } else {
                        item.join("\n\n");
                    }
                }).join("\n\n"));
            } else {
                let enc = new TextDecoder('utf-8');
                let res = JSON.parse(enc.decode(new Uint8Array(data)));
                //return res;
                errors.push(res);
            }
        }

        return errors.join("\n\n");
    }

    const request = async (method, id = 0, params = []) => {
        console.log('API start request to ' + method + '()');
        try {
            const token = await getAccessTokenSilently();
            const response = await functions[method](token, id, params);
            //console.log('API responseData', response);
            return response.data;

        } catch (e) {
            if (e.response) {
                console.log('API Axios Error Response:', e.response);
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                setError(e.response.status + " " + e.response.statusText + ":\n\n" + getValidationData(e.response.data));
            } else if (e.request) {
                console.error('API Axios Error Request:', e);
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                setError(JSON.stringify(e.request));
            } else {
                console.error('API Axios Error Other:', e);
                // Something happened in setting up the request that triggered an Error
                setError(e.message);
            }
        } finally {
            console.log('API end request to ' + method + '()');
        }
    };

    useEffect(() => {
        if (error.length > 0) {
            toast.error(error, {
                duration: 6000,
                style: {
                    border: "1px solid green",
                    padding: "16px",
                    //color: "green",
                },
                position: 'top-center',
            });
            //addToast(`Error: ${error}`, { appearance: 'error', autoDismiss: true })
            setError(""); // important to set it as empty
        }
    }, [error]);

    return { request, error };
};